@import "../../../assets/styles/global";


.users-page {
  @include pageWrapper;

  .tab-and-search {
    @include spaceBetween;
    padding: 24px 0;
  }

  .user-table-wrapper {
    .table-wrapper-desktop {
      .table-container {
        height: 64vh;

        @media (max-width: $desktop) {
          height: 60vh
        }
      }
    }

    .table-wrapper-mobile {
      .table-wrapper-mobile-wrapper {
        height: 66vh;
      }
    }
  }
}