@import "../../assets/styles/global";

.not-found-wrapper {
  font-family: Outfit, sans-serif;

  .not-found-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 305px 0 255px;

    @media (max-width: $desktop) {
      padding: 160px 0 231px;
    }

    @media (max-width: $tablet) {
      padding: 127px 0 103px;
    }

    .not-found-svg {
      width: 224px;
      height: 158px;

      @media (max-width: $desktop) {
        max-width: 153px;
        max-height: 108px;
      }

      @media (max-width: $tablet) {
        max-width: 115px;
        max-height: 81px;
      }
    }

    .not-found-title {
      color: #000;
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      margin-top: 40px;

      @media (max-width: $desktop) {
        margin-top: 39px;
      }

      @media (max-width: $tablet) {
        margin-top: 33px;
        font-size: 24px;
      }
    }

    .not-found-desc {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 21px 0 40px;

      @media (max-width: $desktop) {
        margin: 16px 0 29px;
      }

      @media (max-width: $tablet) {
        margin: 12px 0 28px;
      }
    }
  }
}