@import "../../../assets/styles/global";

.home-page {
  background: $white;
  @media (min-width: $tablet) {
  }
  @media (min-width: $desktop) {
  }


  // Transform Section

  .transform-wrapper {
    width: 100%;
    height: 453px;
    position: relative;


    @media (min-width: $tablet) {
      height: 516px;
    }
    @media (min-width: $desktop) {
      height: 539px;
    }

    .transform_background-small {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: no-repeat url("../../../assets/images/img/homeBannerMobileSmall.png");
      background-size: 100% 100%;

      @media (min-width: $tablet) {
        background: url("../../../assets/images/img/homeBnnerTabletSmall.png") no-repeat;
        background-size: 100% 100%;
      }
      @media (min-width: $desktop) {
        background: url("../../../assets/images/img/homeBnnerSmall.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .transform__background-big {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url("../../../assets/images/img/homeBnnerMobile.png") no-repeat;
      background-size: 100% 100%;

      @media (min-width: $tablet) {
        background: url("../../../assets/images/img/homeBnnerTablet.png") no-repeat;
        background-size: 100% 100%;
      }
      @media (min-width: $desktop) {
        background: url("../../../assets/images/img/homeBnner.png") no-repeat;
        background-size: 100% 100%;
      }
    }


    .transform-section {
      position: absolute;
      right: 0;
      left: 0;
      @include containerWithPadding;
      @include spaceBetween;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
      }

      .transform-section-box {
        @include column;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 105px;


        @media (min-width: $tablet) {
          text-align: center;
          padding-top: 119px;
        }
        @media (min-width: $desktop) {
          text-align: start;
          padding-top: 145px;
        }
      }

      .transform-title {
        color: $white;
        text-align: center;
        font-family: Outfit, sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        letter-spacing: -1px;


        @media (min-width: $tablet) {
          line-height: 60px;
          font-size: 56px;
        }

        @media (min-width: $desktop) {
          color: $white;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 56px;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -1px;
        }
      }

      .transform-subtitle {
        color: $white;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 16px;


        @media (min-width: $tablet) {
          font-size: 18px;
          font-weight: 600;
          margin-top: 24px;
        }

        @media (min-width: $desktop) {
          max-width: 750px;

        }
      }

      .transform-subtitle-bold {
        color: $white;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
      }

      .transform-action-box {
        @include alignCenter;
        gap: 20px;
        margin-top: 24px;
        flex-direction: column;
        @media (min-width: $tablet) {
          flex-direction: row;
          justify-content: center;
        }
        @media (min-width: $desktop) {
          margin-top: 40px;
          justify-content: start;
        }

        .main-button {
          width: 100%;
          max-width: 343px;
          @media (min-width: $tablet) {
            max-width: 185px;
          }
        }
      }

      .transform-raise {
        color: $textColor;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: start;
        @media (min-width: $tablet) {
          width: 191px;
        }
        @media (min-width: $desktop) {
          width: 236px;
        }
      }
    }
  }


  .transform-frame {
    img {
      width: 328px;
      height: 264px;
      margin-top: 41px;
      @media (min-width: $tablet) {
        width: 574px;
        height: 461px;
        margin-top: 59px;
      }
      @media (min-width: $desktop) {
        width: 570px;
        height: 458px;
      }
    }
  }


  // Transform Section End


  // Banner Cards Section

  .banner-cards-wrapper {
    position: relative;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -35px;
    gap: 32px;
    z-index: 2;

    @media (min-width: $tablet) {
      margin-top: -100px;
      align-items: center;
    }

    @media (min-width: $desktop) {
      max-width: 100%;
      flex-direction: row;
      gap: 32px;
      margin-top: -150px;
    }

    .banner-card-item {
      display: flex;

      @media (min-width: $tablet) {
        max-width: 556px;
      }
      @media (min-width: $desktop) {
        max-width: 556px;
      }
    }
  }

  // Banner Cards Section End

  // HowItWorks Section
  .how-it-works-wrapper {
    padding: 72px 0 96px;

    @media (min-width: $tablet) {
      padding: 96px 0 96px;

    }

    @media (min-width: $desktop) {
      padding: 140px 0 140px;
    }

    .how-it-works-box {

      .how-it-works-title {
        color: $textDark;
        font-family: Outfit, sans-serif;
        font-size: 40px;
        font-weight: 600;
        text-align: center;

        @media (min-width: $tablet) {
          font-size: 48px;

        }

      }

      .how-it-works-tabs {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 6px;
        margin: 54px 0 80px;

        @media (min-width: $tablet) {
          flex-direction: row;
          margin: 48px 0;
        }

        @media (min-width: $desktop) {
          margin: 45px 0 36px;
        }


        .how-it-works-tab {
          max-width: 320px;
          width: 100%;
          color: $darkGray;
          font-family: Montserrat, sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          border-radius: 45px;
          text-align: center;
          background: $borderGray;
          cursor: pointer;
          padding: 14px 32px;
          transition: all .2s;

          @media (min-width: $tablet) {
            max-width: 253px;
          }

          @media (min-width: $desktop) {
            max-width: fit-content;
          }

          &:hover {
            background: $blue2;
            color: $white;
            transition: all .2s;
          }

          &.active-tab {
            background: $blue2;
            color: $white;
            transition: all .2s;
          }
        }
      }

      .how-it-works-current-items-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        @media (max-width: $desktop) {
          display: none;
        }

        .current-items-desktop-header {
          width: 76%;
          display: flex;
          align-items: center;
          gap: 16px;

          .border-svg-block {
            width: 100%;
            display: flex;
            gap: 13px;
            align-items: center;

            &.width-fit-content {
              width: fit-content;
            }

            .current-desktop-items-svg-block {
              min-width: 80px;
              min-height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background: $backGreen;
            }

            .current-desktop-item-border {
              width: 100%;
              border-top: 2px dashed #000624;
            }
          }
        }

        .current-items-desktop-content {
          display: flex;
          gap: 60px;

          .current-item-desktop-title_desc {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .how-it-works-current-desktop-item-title {
              color: var(--Neutral-Colors-Rich-Navy, #000624);
              text-align: center;
              font-family: Rubik, sans-serif;
              font-size: 32px;
              font-weight: 500;
            }

            .how-it-works-current-desktop-item-desc {
              color: var(--Neutral-Colors-Rich-Navy, #000624);
              text-align: center;
              font-family: Montserrat, sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }

      .how-it-works-current-items {
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (min-width: $tablet) {
          align-items: center;
        }

        @media (min-width: $desktop) {
          display: none;
        }

        .how-it-works-current-item-wrapper {
          display: flex;
          gap: 16px;

          @media (min-width: $tablet) {
            max-width: 398px;
            flex-direction: column;
            margin-top: -43px;
          }

          @media (min-width: $desktop) {
            position: static;
            gap: 32px;
            margin: 0;
          }

          &.item-right-back {

            @media (min-width: $tablet) {
              padding-right: 16px;
              background: url("../../../assets/images/svg/howItWork/Dashed1Img.png") no-repeat right top 11px;
              background-size: auto 100%;
              padding-bottom: 67px;
              margin-top: 0;
            }

            @media (min-width: $desktop) {
              background: none;
              margin: 0;
              padding: 0;
            }
          }

          &.item-left-back {
            @media (min-width: $tablet) {
              padding-left: 16px;
              background: url("../../../assets/images/svg/howItWork/Dashed2Img.png") no-repeat left top 30px;
              background-size: auto 85%;
              padding-bottom: 67px;
              margin-top: -33px;

            }

            @media (min-width: $desktop) {
              background: none;
              margin: 0;
              padding: 0;
            }
          }


          .how-it-works-current {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;

            @media (min-width: $desktop) {
              width: 100%;
              margin-left: 35%;
              gap: 13px;
              flex-direction: row;
            }

            .how-it-works-current-item-svg-block {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 56px;
              height: 56px;
              padding: 12px;
              border-radius: 16px;
              background: $backGreen;

              @media (min-width: $tablet) {
                border-radius: 8px;
                width: fit-content;
              }

              @media (min-width: $desktop) {
                width: 80px;
                height: 80px;
              }


              .how-it-works-current-item-svg {
                width: 40px;
                height: 40px;

                @media (min-width: $desktop) {
                  width: 56px;
                  height: 56px;
                }
              }
            }

            .how-it-works-current-item-border {
              display: block;
              height: 107px;
              border-left: 2px dashed $richNavy;

              @media (min-width: $tablet) {
                display: none;
              }

              @media (min-width: $desktop) {
                width: 100%;
                height: 0;
                display: block;
              }

            }

            .how-it-works-dashed-svg1 {
              position: absolute;
              right: 0;
            }

          }


          .current-item-title_desc {

            .how-it-works-current-item-title {
              color: $richNavy;
              font-family: Rubik, sans-serif;
              font-size: 24px;
              font-weight: 600;
              line-height: 36px;
              margin-bottom: 8px;

              @media (min-width: $tablet) {
                font-size: 32px;
                font-weight: 500;
                margin-bottom: 16px;
                text-align: center;
              }

              @media (min-width: $desktop) {
                text-align: center;

              }
            }

            .how-it-works-current-item-desc {
              color: $richNavy;
              font-family: Montserrat, sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;

              @media (min-width: $tablet) {
                text-align: center;
              }

              @media (min-width: $desktop) {
                text-align: center;
              }
            }
          }

        }
      }
    }
  }

  //HowItWorks Section End

  //JoinMovement Start

  .join-movement-wrapper {
    padding: 48px 0;
    background: $backGray;

    .join-movement-header {
      @media (min-width: $tablet) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
      }

      .join-movement-title {
        color: $richNavy;
        font-family: Outfit, sans-serif;
        font-size: 36px;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        white-space: nowrap;

        @media (min-width: $tablet) {
          font-size: 40px;
        }
      }

      .ready-card-button-tablet {
        display: none;

        @media (min-width: $tablet) {
          display: block;
          width: 100%;
          max-width: 198px;
          background: transparent;
          border: 1px solid #000;

          .flex-all-center {
            p {
              color: $richNavy;
            }
          }
        }
      }
    }

    .join-movement-content-desktop {
      width: 100%;
      display: none;

      @media (min-width: $desktop) {
        display: flex;
        gap: 16px;
        margin: 48px 0 0;
      }

      .join-movement-content-left-card {
        cursor: pointer;
        width: 50%;

        .sticker-img-wrapper {
          .join-movement-card-img-block {
            width: 100%;
            height: 417px;
          }

          .join-movement-sticker {
            width: 86px;
            height: 43px;
            color: black;
          }
        }
      }

      .join-movement-content-right-cards {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .right-single-card {
          cursor: pointer;
          height: fit-content;
          width: calc(100% / 2 - 8px);

          .join-movement-card-img-block {
            width: 100%;
            height: 138px;
          }

          .join-movement-card-title {
            font-size: 16px;
            line-height: 20px;
          }


        }
      }
    }

    .join-movement-carousel-wrapper {
      margin: 32px 0;

      @media (min-width: $tablet) {
        margin: 24px 0 0;
      }

      @media (min-width: $desktop) {
        display: none;
        margin: 48px 0 0;
      }


      .swiper-wrapper {

        .swiper-slide {
          .join-movement-carousel-card {
            width: 100%;

            .sticker-img-wrapper {
              height: 257px;

              @media (min-width: $tablet) {
                height: 417px;
              }

              .join-movement-card-img-block {
                height: 100%;
              }
            }
          }
        }
      }
    }

    .ready-card-button-mobile {
      width: 100%;
      max-width: 330px;
      background: transparent;
      border: 1px solid #000;
      margin: 0 auto;

      @media (min-width: $tablet) {
        display: none;
      }

      .flex-all-center {
        p {
          color: $richNavy;
        }
      }
    }
  }

  //JoinMovement End


  //Join With
  .join-with-wrapper {
    padding: 72px 0 96px;

    @media (min-width: $tablet) {
      padding: 96px 0 96px;
    }

    @media (min-width: $desktop) {
      padding: 140px 0;
    }

    .oin-with-container {
      max-width: 1051px;
      width: 100%;
      margin: 0 auto;
      padding: 0 24px;

      @media (min-width: $tablet) {
        padding: 0 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 100px;
      }
    }

    .join-with-box {
      .join-with-title {
        color: $richNavy;
        font-family: Outfit, sans-serif;
        font-size: 36px;
        font-weight: 600;
        line-height: 46px;
        text-transform: capitalize;
        margin-bottom: 40px;

        @media (min-width: $tablet) {
          text-align: center;
          font-size: 40px;
        }
        @media (min-width: $desktop) {
          margin-bottom: 48px;
        }
      }

      .join-with-cards-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (min-width: $lg) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        .join-with-dingle_card {
          @media (min-width: $lg) {
            width: calc(100% / 2 - 8px);
          }
        }
      }
    }
  }

  //Join With End

  //MakingDifference START
  .making-difference-wrapper {
    padding-bottom: 96px;

    @media (min-width: $desktop) {
      padding-bottom: 140px;
    }

    .making-difference-box {
      .making-difference-header {
        .green-text {
          color: $buttonBeck;
          font-family: Outfit, sans-serif;
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
        }

        .making-difference-title {
          color: $richNavy;
          font-family: Outfit, sans-serif;
          font-size: 36px;
          font-weight: 600;
          line-height: 46px;
          margin: 8px 0 16px;

          @media (min-width: $tablet) {
            font-size: 40px;
            margin: 8px 0 24px;
          }
        }

        .making-difference-desc-and-button {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 16px;
          margin-bottom: 17px;

          @media (min-width: $tablet) {
            margin-bottom: 40px;
          }

          @media (min-width: $desktop) {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 48px;
          }

          .making-difference-desc {
            color: $richNavy;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            @media (min-width: $desktop) {
              max-width: 722px;
            }
          }

          .arrow-buttons {
            width: 60px;
            min-width: 60px;
            min-height: 60px;
            border: none;
            background: none;
            padding: 0;

            &:disabled {
              pointer-events: none;

              .arrow-buttons-svg {
                path {
                  fill: $borderGray;
                }

              }
            }

            .arrow-buttons-svg {
              width: 100%;
              height: 100%;

              path {
                fill: $blue2;
              }

              &.arrow-buttons-svg-left {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .making-difference-carousel-wrapper {

        .swiper-wrapper {
          width: max-content;
          gap: 16px;

          @media (min-width: $tablet) {
            gap: 24px;
          }

          .making-difference-carousel-card {
            border: 1px solid $borderGray;
            cursor: pointer;

            .sticker-img-wrapper {
              .join-movement-card-img-block {
                width: 100%;
                height: 260px;

                @media (min-width: $tablet) {
                  width: 100%;
                  height: 260px;
                }

                img {
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }

  //MakingDifference END
}

//ReadyCard
.ready-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 21px;
  border-radius: 16px;
  padding: 24px;
  cursor: pointer;
  transition: all .2s;

  @media (min-width: $desktop) {
    padding: 33px;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgba(5, 5, 5, 0.25);
    transform: scale(1.02);

    .ready-card_title-svg {
      .ready-card_svg {
        opacity: 1;
        visibility: visible;
        transition: all .2s;
      }
    }
  }

  .ready-card_title-svg {
    @include alignCenter;
    gap: 16px;

    @media (min-width: $tablet) {
      gap: 24px;
    }

    .ready-card-title {
      max-width: 291px;
      color: $white;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;

      @media (min-width: $tablet) {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
      }

      @media (min-width: $desktop) {
        max-width: 375px;
      }
    }

    .ready-card_svg {
      width: 48px;
      height: 48px;
      opacity: 0;
      visibility: hidden;
      transition: all .2s;

      path {
        fill: white;
      }
    }
  }


  .ready-card-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ready-card-item {
      display: flex;
      align-items: start;
      gap: 14px;

      .ready-card-svg {
        min-width: 20px;
        min-height: 20px;

        rect {
          fill: $white;
          stroke: $white;
        }

        path {
          stroke: var(color);
        }
      }

      .ready-card-item-text {
        color: $white;
        font-family: Outfit, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
    }
  }

  .ready-card-button-wrapper {
    background: $white;

    p {
      color: $richNavy;
    }

    &:hover {
      background: $white;
    }
  }
}

//JoinMovementCard START

.join-movement-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding: 16px;
  border-radius: 16px;
  background: $white;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    box-shadow: 0 8px 12px 0 rgba(156, 156, 156, 0.25);
    transition: all .2s;

    .join-movement-card-img-block {
      transform: scale(1.05);
      transition: all .2s;
    }
  }

  .sticker-img-wrapper {
    position: relative;


    .join-movement-card-img-block {
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      transition: all .2s;

      .swiper-pagination {
        width: fit-content;
        border-radius: 27px;
        background: rgba(0, 0, 0, 0.45);
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 4px 8px;
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: $borderGray;
      }

      .swiper-pagination-bullet-active {
        background: $buttonBeckHover;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .join-movement-sticker {
      position: absolute;
      z-index: 2;
      @include flexCenter;
      gap: 7px;
      top: 9px;
      left: 9px;

      color: $white;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      background: $richNavy;
      border-radius: 16px;
      padding: 6px 22px;

      .join-movement-sticker-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $white;
      }

      &.join-movement-sticker-celebration {
        color: $richNavy;
        background: $yellow;

        .join-movement-sticker-circle {
          background: $richNavy;
        }
      }

      &.join-movement-sticker-dont-live {
        color: $richNavy;
        background: $borderGray;

        .join-movement-sticker-circle {
          background: $richNavy;
        }
      }
    }
  }


  .join-movement-card_content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .join-movement-card-title {
      width: 100%;
      color: $richNavy;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }

    .join-movement-card-end-date {
      color: $darkGray;
      font-family: Outfit, sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-top: 4px;
    }

    .join-movement-card-desc {
      color: $richNavy;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .price-percentage-wrapper {
      width: fit-content;
      border-radius: 4px;
      background: rgba(255, 215, 54, 0.20);
      padding: 4px 8px;

      .price {
        color: $richNavy;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 500;
      }

      .percentage-wrapper {
        position: relative;
        width: 100%;
        height: 8px;
        border-radius: 8px;
        background: #E0E0E0;

        .percentage-block {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 8px;
          background: $buttonBeck;
        }
      }
    }

    .buttons-block {
      display: flex;
      gap: 16px;

      .donate-now-button {
        width: 100%;
        padding: 0;
      }

      .see-detail {
        width: 100%;
        background: #F4F4F4;
        padding: 0;

        p {
          color: #000624;
        }
      }
    }
  }

}

//JoinMovementCard End

//JoinWithCard Start

.join-with-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  cursor: pointer;
  background: $pageGray;
  transition: all .2s;

  @media (min-width: $tablet) {
    padding: 24px;
    gap: 24px;
  }

  &:hover {
    background: $blue2;
    transition: all .2s;

    .join-with-card-header {

      .join-with-card-header-title {
        color: $white;

      }
    }

    .join-with-card-desc {
      color: $white;
    }
  }

  .join-with-card-header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $tablet) {
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }


    .join-with-card-header-svg {
      width: 40px;
      height: 40px;
    }

    .join-with-card-header-title {
      color: #000;
      font-family: Outfit, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }
  }

  .join-with-card-desc {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

//JoinWithCard End