@import "../../../assets/styles/global";

.donate-page {
  .donate-page-block {
    @include pageWrapper;
    width: 100%;

    @media (min-width: $tablet) {
      width: 628px;
    }
    @media (min-width: $desktop) {
      width: 1100px;
      flex-direction: row;
    }

    .donate-page-back-btn-box {
      padding: 12px 16px;
      margin: 32px 0 16px;

      @media (min-width: $tablet) {
        padding: 12px 0;
        margin: 24px 0 16px;
      }

      .back-btn-isMemory {
        p {
          color: $borderGray;
        }

        svg {
          path {
            stroke: $borderGray;
          }
        }
      }
    }

    .donate-page-wrapper {
      @include flexStart;
      gap: 24px;
      flex-direction: column;
      @media (min-width: $desktop) {
        flex-direction: row;
      }
    }

    .donate-page-amount-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (min-width: $desktop) {
        width: 493px;
        gap: 24px;
      }
    }

    .donater-information-box {
      @include column;
      gap: 24px;
    }

    .donate-page-card-title {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .donate-page-card-box {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid $borderGray;
      background: $white;
      @media (min-width: $tablet) {
        padding: 24px;
      }
    }

    .send-item-donation-wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;

      .send-item-title-button_box {
        @include spaceBetween;
        gap: 8px;
        margin: 16px 0;
        padding: 0 12px;

        .send-item-title {
          color: #000;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        .see-all-item-donation-button {
          @include resetBtn;
          margin-bottom: 8px;

          color: $buttonBeck;
          text-align: center;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }

      }

      .send-item-donation-swiper {
        width: 100%;

        .swiper-slide {
          width: auto;
          flex-shrink: 0;
        }
      }
    }

    .donate-page-amount-box {
      @include alignCenter;
      border-radius: 8px;
      border: 1px solid $borderGray;

      &.donate-input-error {
        border: 1px solid $red;

        div {
          border-right: 1px solid $red;
        }
      }

      div {
        padding: 20px 10px;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        border-right: 1px solid $borderGray;
      }

      input {
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        outline: none;
        padding: 0 10px;
        color: #000;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .donate-page-amounts {
      display: flex;
      margin-top: 21px;
      gap: 8px;
      flex-wrap: wrap;


      button {
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid $borderGray;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .donate-page-nickname {
      height: 64px;
      padding: 0 16px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $borderGray;
      font-family: Outfit, sans-serif;
      color: $darkGray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      outline: none;

      &.donate-page-memory-nickname {
        border: 1px solid $darkGray;
        color: $borderGray;
        background: $chipBack3;
      }

      &.donate-input-error {
        border: 1px solid $red;
      }

      @media (min-width: $tablet) {
        padding: 0 24px;
      }
    }

    .donate-page-text-area {
      height: 110px;
      padding: 12px 16px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $borderGray;
      font-family: Outfit, sans-serif;
      color: $darkGray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      outline: none;
      resize: none;

      &.donate-page-text-area_memory {
        border: 1px solid $darkGray;
        color: $borderGray;
        background: $chipBack3;
      }

      &.donate-input-error {
        border: 1px solid $red;
      }

      @media (min-width: $tablet) {
        padding: 12px 24px;
      }
    }

    .donate-page-nickname-subtitle {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 16px;
    }

    .donate-page-charity-btn {
      @include resetBtn;
      @include spaceBetween;
      gap: 16px;
      margin-bottom: 24px;

      div {
        @include flexCenter;
        gap: 16px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .donate-page-charity-btn:last-of-type {
      margin-bottom: 0;
    }

    .pay-type-wrapper {
      @include spaceBetween;
      padding: 16px;
      gap: 11px;
      border-radius: 8px;
      border: 1px solid $borderGray;
      background: #FFF;
      @media (min-width: $tablet) {
        padding: 16px 24px;
      }

      .pay-type-box {
        @include alignCenter;
        width: 55px;
        height: 32px;
        gap: 2px;
        border-radius: 4px;
        border: 1px solid #000;
        padding: 7px;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        .applePay-memory {

          path {
            fill: $borderGray;
          }
        }
      }

      .pay-type-text {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-left: 11px;
      }

      .MuiRadio-root {
        padding: 0;
        color: $blue2;
      }

      &.pay-type-memory-wrapper {
        .MuiRadio-root {
          color: $white;
        }
      }
    }


    .donate-page-contribution-wrapper {
      width: 100%;

      @media (min-width: $desktop) {
        position: sticky;
        top: 168px;
        width: 397px;
      }

      .contribution-summary-title {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 15px;

        @media (min-width: $desktop) {
          margin-bottom: 17px;
          margin-top: -23px;
        }
      }

      .donate-summary-box {
        border-radius: 8px;
        border: 1px solid $borderGray;
        background: $white;
      }

      .donate-summary-head {
        padding: 16px 16px 36px;
        border-bottom: 1px solid $borderGray;
        @include alignCenter;
        gap: 12px;

        img {
          width: 81px;
          height: 79px;
          border-radius: 8px;
          box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
          object-fit: cover;
        }

        p {
          color: #000;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }
      }

      .donate-summary-body {
        @include column;
        width: 100%;

        .full-width {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        .donate-summary-info-box {
          @include spaceBetween;
          padding: 0 16px;

          .active-item-in-contribution {
            display: flex;
            align-items: center;
            gap: 8px;

          }

          p {
            color: #000;
            font-size: 16px;
            font-family: Outfit, sans-serif;
            font-weight: 500;
            line-height: 24px;
            white-space: nowrap;
          }

          &.donate-summary-info-box-memory {
            p {
              color: $borderGray;
            }
          }
        }

        .donate-summary-bold-info-box {
          @include spaceBetween;

          p {
            color: #000;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
          }

          &.donate-summary-bold-info-box-memory {
            p {
              color: $borderGray;
            }
          }

          &.donate-summary-bold-info_total {
            background: $backGreen;
            padding: 16px;
          }
        }
      }

      .terms-and-conditions {
        @include flexStart;
        gap: 6px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 15px 0 24px;
        @media (min-width: $tablet) {
          margin: 26px 0 22px;
        }

        & .MuiButtonBase-root {
          padding: 0;
        }

        a {
          text-decoration: underline;
          color: #000;
        }
      }

      .main-button {
        width: 100%;

        &.donate-button-memory {
          background: $white;

          svg {
            path {
              fill: $chipBack3;
            }
          }

          p {
            color: $chipBack3;
          }
        }
      }
    }
  }
}

.see-items-modal-wrapper {
  width: 856px;

  @media (max-width: $tablet) {
    width: 100%;
  }

  .see-items-modal-budget {
    color: $darkGray;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .see-items-search_input-filter {
    display: flex;
    gap: 11px;
    margin-bottom: 16px;

    .see-items-search_input {
      width: 100%;
      height: 48px;
      background: $pageGray;

      input {
        background: $pageGray;
        color: $darkGray;
      }
    }
  }

  .see-items-box {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-content: start;
    height: auto;
    max-height: 515px;
    overflow: auto;

    .see-items_single {
      width: calc(100% / 4 - 13px);
      height: fit-content;

      &.favor-small-card-active {
        .favor-small-card-box {
          border: 2px solid $buttonBeck;
        }
      }

      .favor-small-card-box {
        border: 2px solid $borderGray;

        .favor-small-card-image {
          height: 151px;

          .favor-small-card_action-checkbox-button {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      display: block !important;
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #555768;
      border-radius: 10px;
    }
  }

  .modal2-body-footer-wrapper {
    .modal2-footer-wrapper {
      justify-content: end;

      button {
        width: fit-content;
      }
    }
  }
}

