// Project Global Style.

$white: #ffffff;
$reddishBrown: #AD3501;
$buttonBeck: #34A853;
$buttonBeckHover: #389C53;
$backGreen: #DBEDDB;

$reddishBrownHover: #942F04;
$red: #D03325;
$red2: #E63946;
$richNavy: #000624;
$disableGray: rgba(103, 103, 103, 0.20);
$darkGray: #555768;
$chipGray: #323A42;
$chipBack: #303841;
$chipBack2: #222831;
$chipBack3: #1C212D;
$textColor: #2A2F35;
$textColor1: #3D4751;
$textColor2: #51566D;
$textColor3: #2A374E;
$textDark: #1D1D1D;
$beckBlack: #242A31;
$beckBlack2: #0D111D;
$olive: #808000;
$blue: #02357A;
$blue2: #004080;
$borderBottom: #717272;
$borderBottom2: #E7EFFB;
$backgroundGray: #F7F7F7;
$oliveLight: #D4AF37;
$yellow: #FFD736;
$pageGray: #F4F4F4;
$borderGray: #E0E1E2;
$backGray: #F8F8F8;


// Size Option.
$xs: 320px;
$sm: 375px;
$tablet: 767px;
$lg: 1024px;
$desktop: 1279px;
$xl: 1440px;


//Font Family.
$ffMontserrat: 'Montserrat', sans-serif;

@mixin pageWrapper {
  padding: 68px 16px 72px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 68px 40px;
  }
  @media (min-width: 1279px) {
    padding: 80px 100px 100px;
  }
}

@mixin containerNoPadding {
  width: 100%;
  max-width: 1444px;
  margin: 0 auto;
}

@mixin containerWithPadding {
  width: 100%;
  max-width: 1444px;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }
  @media (min-width: 1279px) {
    padding: 0 100px;
  }
}

@mixin containerWithPaddingNew {
  width: 100%;
  max-width: 1444px;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }
  @media (min-width: 1279px) {
    padding: 0 170px;
  }
}


@mixin resetBtn {
  background: transparent;
  border: none;
  padding: 0;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@mixin alignCenter {
  display: flex;
  align-items: center;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexStart {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@mixin whiteBox {
  border-radius: 8px;
  background: #FFF;
  padding: 16px;

  @media (min-width: $tablet) {
    padding: 24px 16px;
  }
  @media (min-width: $desktop) {
    padding: 24px 32px;
  }
}

@mixin olive20px {
  color: $olive;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  @media (min-width: $tablet) {
    padding: 0 40px;
  }
  @media (min-width: $desktop) {
    padding: 0 100px;
  }
}

.container2 {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: $tablet) {
    padding: 0 32px;
  }
  @media (min-width: $desktop) {
    padding: 0 50px;
  }
}


.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media (max-width: $tablet) {
    padding: 0 32px;
  }

  @media (max-width: $desktop) {
    padding: 0 16px;
  }
}

#popover-my-profile {
  margin-top: 8px;

  .MuiPaper-root {
    width: 230px;
    top: 80px !important;
    right: 10px !important;
    left: auto !important;
    height: 290px;
    padding: 12px 6px;
    border-right: 4px;
  }
}


.desktop-box {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet-box {
  display: none;
  @media (min-width: 768px) and (max-width: 1119px) {
    display: block;
  }
  @media (max-width: $tablet) {
    display: none;
  }
}

.mobile-box {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.ReactSwipeableBottomSheet--open {
  border-radius: 8px 8px 0 0 !important;
}

.react-swipeable-view-container {
  box-shadow: none !important;
}

// Default Style.

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.full-width {
  width: 100%;
}

.image-and-name-row {
  @include alignCenter;
  width: 100%;
  gap: 10px;

  .image-box {
    width: 32px;
    height: 32px;
    min-height: 32px;

    img {
      width: 32px;
      height: 32px;
      min-height: 32px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}

.link-row {
  p {
    color: $blue2;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  color: $blue2;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 32px white inset !important;
  border-radius: 0;
}

.router-wrapper {
  padding: 80px 0 0 0;
  background: #F0F2F5;
}

.desktop-box {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.app-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.page-global-title {
  color: #222;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 32px;
  }
  @media (min-width: 1279px) {
    font-size: 40px;
    line-height: 60px;
  }
}

.page-global-title-small {
  color: #222;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 1279px) {
    font-size: 32px;
    line-height: 60px;
  }
}


.mobile-view {
  display: block;
  @media (min-width: $tablet) {
    display: none;
  }
}

.mobile-and-tablet-view {
  display: block;
  @media (min-width: $desktop) {
    display: none;
  }
}

.tablet-and-desktop-view {
  display: none;
  @media (min-width: $tablet) {
    display: block;
  }
}


.desktop-view {
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.view-all-btn {
  width: fit-content;
  border-bottom: 1px solid;

  a {
    @include alignCenter;
    gap: 4px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
    border: none;
  }
}

.rdrCalendarWrapper {
  background: transparent;
}

.rdrDateDisplayWrapper {
  background: transparent;
}

.rdrDefinedRangesWrapper {
  background: transparent;
  @media (max-width: $tablet) {
    display: none;
  }
}

.rdrStaticRangeSelected {
  border-radius: 8px 0 0 0;
}

.rdrDateRangePickerWrapper {
  box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  border-radius: 8px;
  background: white;
}

.react-multiple-carousel__arrow--right {
  display: none;
  right: 0px;
  @media (min-width: $desktop) {
    display: block;
  }
}

.react-multiple-carousel__arrow--left {
  display: none;
  left: 0px;
  @media (min-width: $desktop) {
    display: block;
  }
}

.MuiModal-root {
  z-index: 1405;
}


.modal-wrapper {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 16px;
  height: auto;
  max-height: 85vh;
  overflow: auto;
  @media (min-width: $tablet) {
    padding: 24px 32px;
  }


  .modal-header-style {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 16px;
    top: 16px;

    .close-modal-btn {
      @include flexCenter;
      border-radius: 32px;
      background: #F5F5F5;
      width: 28px;
      height: 28px;
      border: none;
    }
  }

  .modal-body-style {
    height: 100%;
  }
}

.beck-drop {
  height: 100vh;
  width: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
}

.search-box-tablet {
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.search-box-mobile {
  margin-top: 16px;
  display: block;
  @media (min-width: $desktop) {
    display: none;
  }
}

.olive-text {
  color: $olive;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.reset-btn {
  @include resetBtn
}


.modal2-header {
  @include flexCenter;
  justify-content: space-between;
  padding: 16px 32px;
  border-bottom: 1px solid $borderGray;

  @media (max-width: $tablet) {
    padding: 16px;
  }

  .modal2-header_title {
    color: $richNavy;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

  }

  .modal2-header_title ~ svg {
    cursor: pointer;
  }
}

.modal2-body-footer-wrapper {
  .modal2-body-wrapper {
    padding: 16px 32px;

    @media (max-width: $tablet) {
      padding: 16px;
    }
  }

  .modal2-footer-wrapper {
    @include flexCenter;
    gap: 18px;
    border-top: 1px solid $borderBottom2;
    background: $white;
    padding: 16px 32px;

    @media (max-width: $tablet) {
      padding: 16px;
    }

    button {
      width: 100%;
    }
  }
}
.modal2-footer-cancel-button {
  width: 100%;
  @include resetBtn;
  @include flexCenter;
  background: $white;
  padding: 12px 36px;
  border-radius: 4px;

  color: $richNavy;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: all .2s;

  &:hover {
    color: $buttonBeck;
    transition: all .2s;
  }
}
