@import "../../assets/styles/global";

.update-account-details-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 543px;

  .update-account-details-svg {
    width: 60px;
    height: 60px;
    margin: 0 auto;

    @media (min-width: $tablet) {
      width: 100px;
      height: 100px;
    }
  }

  .update-account-details_title {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 24px 0;

    @media (min-width: $tablet) {
      font-size: 32px;
      margin: 40px 0 24px;
    }
  }

  .update-account-details_content-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    background: rgba(231, 181, 18, 0.20);
    padding: 16px;

    .update-account-details_content-title {
      color: $richNavy;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.096px;
    }

    .update-account-details_content {
      color: $richNavy;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .update-account-details-btn_block {
    display: flex;
    gap: 18px;
    margin-top: 24px;

    @media (min-width: $tablet) {
      margin-top: 40px;
    }

    .update-account-details-btns {
      width: 50%;
    }

    .update-account-details-cancel_btn {
      background: transparent;
      border: 1px solid $borderGray;

      p {
        color: $richNavy;
      }
    }
  }
}