@import "../../../assets/styles/global";


.layout-wrapper {
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 68px;
  background: transparent;

  @media (max-width: $tablet) {
  }

  @media (min-width: $desktop) {
    height: 80px;
  }

  .layout-logo-box {
    h1 {
      color: $richNavy;
    }
  }

  .search-form-header {
    width: 100%;

    .search-input-header {
      width: 100%;
      background: $pageGray;

      @media (min-width: $tablet) {
        width: 100%;
      }
      @media (min-width: $desktop) {
        width: 100%;
      }

      input {
        background: $pageGray;
      }
    }
  }


  .layout-box {
    height: 100%;
    @include spaceBetween;
    gap: 36px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 11px 16px;

    @media (min-width: $tablet) {
      padding: 11px 40px;
    }
    @media (min-width: $desktop) {
      padding: 16px 100px;
    }
  }


  .layout-logo-box {
    padding: 10px 24px;

    a {
      color: $white;
      text-decoration: none;
    }
  }


  .search-button {
    @include resetBtn;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    border-left: 1px solid $borderGray;


    .search-button-text {
      display: none;
      color: $white;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media (min-width: $tablet) {
        display: block;
      }
    }

  }

  .layout-actions-box {
    @include alignCenter;
    gap: 12px;

    .main-button {
      display: none;
      @media (min-width: $tablet) {
        display: block;
      }

      &.start_memoryBtn {
        background: #FFF;

        p {
          color: $chipBack3;
        }
      }
    }
  }

  .layout-login-btn {
    @include alignCenter;
    @include resetBtn;
    gap: 8px;
    padding: 12px 36px;
    color: $white;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
  }
}


.logged-popper {
  @include alignCenter;
  @include resetBtn;
  gap: 10px;
  padding: 0;

  p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .logged-popper-image {
    @include flexCenter;
    width: 36px;
    height: 36px;
    border-radius: 32px;
    overflow: hidden;


    svg {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logged-popper-arrow {
    path {
      stroke: var(--color);

    }
  }
}

.logged-links-box {
  margin-top: 16px;
  background: $white;
  width: 100vw;
  min-height: 200px;

  @media (min-width: $tablet) {
    width: 253px;
    border-radius: 8px;
    box-shadow: 0 8px 12px 0 rgba(156, 156, 156, 0.25);
  }

  .link-btn {
    @include alignCenter;
    @include resetBtn;
    width: 100%;
    gap: 10px;
    padding: 10px 16px;
    color: $richNavy;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    justify-content: center;
    border-radius: 4px;
    transition: all .2s;

    @media (min-width: $tablet) {
      justify-content: start;
    }

    &.link-btn-active {
      background: $backGreen;

    }

    svg {
      filter: brightness(0) saturate(100%) invert(10%) sepia(15%) saturate(2413%) hue-rotate(185deg) brightness(91%) contrast(116%);
    }
  }

  .link-btn:hover {
    background: $backgroundGray;
    transition: all .2s;
  }

  .log-out-box {
    border-top: 1px solid #E0E1E2;
    padding: 8px 0;
  }

  .log-out {
    color: $red;

    svg {
      filter: brightness(0) saturate(100%) invert(44%) sepia(92%) saturate(5192%) hue-rotate(337deg) brightness(94%) contrast(91%);
    }
  }

  .logged-links-wrapper {
    padding: 16px;
  }

  &.logged-links-box_memory {
    background: $chipBack3;

    .link-btn {
      color: $borderGray;

      .logged-link-svg_fill {

        path {
          fill: $borderGray;
        }
      }

      .logged-link-svg_stroke {
        path {
          stroke: $borderGray;
        }
      }
    }

    .link-btn:hover {
      background: $beckBlack2;
    }

    .log-out-box {
      border-top: 1px solid $darkGray;
    }

    .log-out {
      color: $red2;
    }
  }
}

.header-links-popper-box {
  width: 100%;
  background: white;
  padding: 24px;

  .layout-links-box-mobile {
    @include alignCenter;
    flex-direction: column;
    gap: 16px;
    @media (min-width: $desktop) {
      display: none;
    }

    a {
      width: fit-content;
      padding: 10px;
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      &:hover {
        color: $reddishBrown;
        border-bottom: 1px solid $reddishBrown;
      }
    }

    .layout-active-link {
      color: $reddishBrown;
      border-bottom: 1px solid $reddishBrown;
    }
  }

  .header-links-popper-button {
    @include flexCenter;
    justify-content: center;
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid #E0E1E2;
  }
}

