@import "../../assets/styles/global";

.title-and-action-box {
  @include spaceBetween;
  padding: 0 0 4px;
  margin-top: 30px;
  border-bottom: 1px solid $borderGray;

  //@media (min-width: $desktop) {
  //  margin-top: 36px;
  //}

  @media (min-width: $tablet) {
    padding: 10px 0 26px;
  }
}

.full-table-styles {
  width: 100%;
  background-color: transparent;

  & .table-wrapper-desktop {
    background: none;
    width: 100%;
    border-radius: 8px;
    position: relative;
    display: none;

    @media (min-width: $tablet) {
      margin-top: 32px;
      display: block;
    }

    @media (min-width: $desktop) {
      margin-top: 24px;
    }

    & .MuiTableContainer-root {
      box-shadow: none;
      background: none;
      ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-scrollbar: {
        display: none;
      }
    }
  }

  .table-wrapper-mobile {
    display: block;
    margin-top: 24px;
    @media (min-width: $tablet) {
      display: none;
    }

    .table-wrapper-mobile-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 60vh;
      overflow: auto;
    }
  }

  .PaginationItem {
    border-radius: 8px;
    border: 1px solid $borderGray;
    background: #FFF;
    margin-bottom: 16px;
    cursor: pointer;
    overflow: hidden;
  }

  .full-width {
    color: $richNavy;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .table-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 58vh;
  }

  .table {
    &.MuiTable-root {
      border-collapse: separate;
      border-spacing: 0 8px
    }
  }

  .table-head {
    border-radius: 8px;
    height: 50px;
    background: rgb(244, 244, 244);


    & > tr {
      border-radius: 8px;

      & > th:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 0;
      }

      & > th:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 0;
      }
    }

    & .MuiTableCell-stickyHeader {
      background: transparent;
    }

    & .MuiTableCell-sizeSmall {
      background: rgb(244, 244, 244);
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: $richNavy;
      padding: 13px 16px;
      border: none;
      @media (min-width: 1920px) {
        padding: 10px 16px 10px 16px;
      }
    }

    .table-cell-box {
      @include spaceBetween;

      p {
        width: 100%;
        font-family: Outfit, sans-serif;
        color: $richNavy;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      button {
        @include resetBtn;
      }
    }
  }


  .table-row {
    cursor: pointer;
    border-radius: 4px;
    background-color: $white;
    margin-top: 8px;

    & td {
      background-color: inherit;
    }

    & > td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & > td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      //background-color: red;

      & > td {
        background-color: inherit;
      }
    }

    height: 50px;

    & .makeStyles-membersTableWrapper-26 .MuiTableContainer-root {
      background: $white;
    }

    & .MuiTableCell-sizeSmall {
      font-size: 16px;
      line-height: 22px;
      border: none;
      padding: 9px 16px;
      color: #000;
      //@media (min-width: 1920px) {
      //  padding: 10px 16px 10px 16px;
      //}
    }

    //& .MuiTableCell-sizeSmall:last-child {
    //  padding-top: 12px;
    //  font-size: 14px;
    //  line-height: 30px;
    //}

    //&.active {
    //  background: red;
    //  cursor: default;
    //}
  }

}


.pagination-wrapper {
  @include spaceBetween;
  justify-content: center;
  width: 100%;
  margin: 24px 0 0;
  padding: 10px 22px;
  border-radius: 4px;
  background: #FFF;
  position: relative;
  z-index: 10;
  @media (min-width: $tablet) {
    justify-content: space-between;
  }

  .pagination-showing-count {
    display: none;
    color: $darkGray;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    @media (min-width: $tablet) {
      display: block;
    }
  }

  & .MuiPaginationItem-root.Mui-selected {
    background: $buttonBeck;
    color: $white;
  }

  & .MuiPaginationItem-textPrimary.Mui-selected {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  & .MuiPaginationItem-page {
    background: none;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  & .MuiPaginationItem-outlined {
    background: $white;
    border: 1px solid white;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: $richNavy;
  }

  & .MuiPaginationItem-page.Mui-selected:hover {
    background: $buttonBeck;
    color: $white;
  }

  &.pagination-wrapper-memory {
    background: $chipBack3;

    .pagination-showing-count {
      color: $borderGray;
    }

    & .MuiPaginationItem-root.Mui-selected {
      background: $white;
      color: $richNavy;
    }

    & .MuiSvgIcon-root {
      path {
        fill: $white;
      }
    }

    & .MuiPaginationItem-page.Mui-selected:hover {
      background: $white;
      color: $richNavy;
    }
  }
}


.detail-page-wrapper {
  background: #FFF;
  border-radius: 8px;

  .detail-page-head {
    @include spaceBetween;
    padding: 8px 16px;
    border-bottom: 1px solid $borderGray;
    @media (min-width: $tablet) {
      padding: 16px 24px;
    }
  }

  .detail-page-title {
    color: $richNavy;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .detail-page-body {
    padding: 16px;
    @media (min-width: $tablet) {
      padding: 24px;
    }
  }

  .detail-page-footer {
    padding: 8px 16px;
    border-top: 1px solid $borderGray;
    @media (min-width: $tablet) {
      padding: 16px 24px;
    }
  }
}

.title-value-box {
  @include alignCenter;
  gap: 8px;
  margin-bottom: 9px;

  .title {
    color: $darkGray;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .value {
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}


.full-table-accordion {
  width: 100%;
  background-color: transparent;


  .full-table-accordion-wrapper {
    background: none;
    width: 100%;
    border-radius: 8px;
    margin-top: 24px;
    position: relative;
    display: none;

    @media (min-width: $tablet) {
      display: block;
    }
  }

  .full-table-accordion-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 1919px) {
      height: 58vh;
    }
  }

  .accordion-table-head {
    border-radius: 8px;
    height: 50px;
    background: transparent;
  }

  .accordion-table-body {
    height: 53vh;
    overflow: auto;

    @media (max-width: $xl) {
      height: 55vh;
    }

    @media (max-width: $desktop) {
      height: 51vh;
    }
  }

  .accordion-table-body-donation {
    height: 53vh;
    overflow: auto;

    @media (max-width: $xl) {
      height: 55vh;
    }

    @media (max-width: $desktop) {
      height: 51vh;
    }
  }

  .accordion-table-cell-box {
    @include spaceBetween;
    padding: 13px 16px;

    p {
      color: $richNavy;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    button {
      @include resetBtn;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    padding: 13px 16px;
  }

  .MuiAccordion-root {
    box-shadow: none;
    border-radius: 8px;

    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    height: 50px;
    min-height: 50px;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    min-height: 50px;
    height: 50px;
    margin-top: 8px;
    box-shadow: none;

    & .Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordion-root::before {
    display: none;
  }

  .MuiAccordionSummary-content {
    margin: 0;

    & .Mui-expanded {
      margin: 0;
    }
  }

  .accordion-table-row {
    cursor: pointer;
    border-radius: 4px;
    background-color: $white;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  .MuiAccordionDetails-root {
    padding: 16px;
    border-top: 1px solid $borderGray;
  }

  .donation-accordion-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .donors-contact-wrapper {
      display: none;

      @media (max-width: $desktop) {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-radius: 8px;
        background: $pageGray;
        padding: 12px 0;
      }

      .donors-contact-title {
        color: $darkGray;
        font-family: Outfit, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 0 16px;
      }

      .donors-contact-body {
        @include spaceBetween;

        .donors-contact-item {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 0 16px;
        }
      }
    }

    .creators-part-wrapper, .charities-part-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 8px;
      padding: 12px;
      background: $backGreen;

      .creators-part-title {
        width: 100%;
        color: $richNavy;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 0 12px;
      }


      .charities-part-box {
        border-radius: 4px;

        @media (max-width: $desktop) {
          padding: 0 8px 8px;
          border-radius: 4px;
          background: $white;
        }

        .charities-part-table-header {
          display: none;

          @media (max-width: $desktop) {
            @include spaceBetween;
          }

          .charities-part-table-header-item {
            width: 100%;
            color: $richNavy;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            padding: 13px 16px;

            &.creator-name-header {
              width: 428px;
            }

            &.creator-net-amount-header {
              width: 252px;
            }

            &.creator-service-fee-header {
              width: 252px;
            }

            &.creator-received-header {
              width: 252px;
            }
          }
        }

        .creators-part-body-wrapper {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .creators-part-body {
            @include spaceBetween;
            border-right: 4px;
            background: $white;

            @media (max-width: $desktop) {
              background: $pageGray;
            }


            .creators-part-body-item {
              width: 100%;
              display: flex;
              gap: 5px;
              align-items: center;
              white-space: nowrap;
              padding: 13px 16px;

              @media (max-width: $desktop) {
                flex-direction: column;
                align-items: start;
                padding: 0;
              }

              .charity-creator-desc {
                color: $richNavy;
                font-family: Outfit, sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;

                @media (max-width: $desktop) {
                  padding: 13px 16px;
                  background: $pageGray;
                }
              }

              &.service-fee {
                width: 252px;

                span {
                  color: $red2;
                }
              }

              &.net-amount {
                width: 252px;
              }

              &.total-received {
                width: 252px;

                span {
                  color: $buttonBeck;
                }
              }

              &.creator-name-block {
                width: 428px;
              }
            }
          }
        }
      }
    }

    .charities-part-wrapper {
      background: $pageGray;
    }

    .charity-creator-name {
      color: $darkGray;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;

      @media (max-width: $desktop) {
        display: none;
      }

      &.show-name {
        display: block;
      }
    }
  }

  .table-wrapper-mobile {
    display: block;
    margin-top: 24px;
    @media (min-width: $tablet) {
      display: none;
    }

    .table-wrapper-mobile-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 57vh;
      overflow: auto;
    }
  }
}

.table-cell-box-sort-icon {
  svg {
    width: 18px;
    height: 18px;
  }
}

.table-mobile-card {
  border-radius: 8px;
  border: 1px solid $borderGray;
  background: $white;
}

.mobile-card-head {
  @include alignCenter;
  gap: 16px;
  padding: 12px 16px;
  border-bottom: 1px solid $borderGray;

  .mobile-card-head-image-box {
    @include flexCenter;
    width: 32px;
    height: 32px;
    border-radius: 8px;

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  p {
    color: #2A374E;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}

.mobile-card-head-box {
  @include alignCenter;
  gap: 4px;
  padding: 12px 16px;
  border-bottom: 1px solid $borderGray;

  span {
    color: #2A374E;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
  }

  p {
    color: #2A374E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.mobile-card-body {
  @include spaceBetween;
  gap: 12px;
  padding: 6px 16px;

  .mobile-card-body-title, p, span {
    color: $darkGray;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }

  .mobile-card-body-value {
    width: 100%;
    text-align: end;
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .mobile-card-body-value p, span {
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.mobile-card-footer {
  @include spaceBetween;
  padding: 12px 16px;
  border-top: 1px solid $borderGray;

  .mobile-card-footer-title {
    color: $darkGray;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .mobile-card-footer-value p, span {
    color: #000624;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.not-yet-section {
  @include flexCenter;
  flex-direction: column;
  width: 100%;

  .not-yet-section-svg {
    width: 76px;
    height: 76px;


    @media (min-width: $tablet) {
      width: 136px;
      height: 136px;
    }
  }

  h1 {
    color: $richNavy;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 16px 0 8px;

    @media (min-width: $tablet) {
      font-size: 32px;
      margin: 21px 0 8px;
    }
  }

  p {
    color: $richNavy;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    @media (min-width: $tablet) {
      font-size: 24px;
    }
  }
}

.payment-success-wrapper {
  width: 100%;
  height: 90vh;
  @include flexCenter;
  font-family: Outfit, sans-serif;

  .payment-success-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 16px;
    width: 100%;
    max-width: 327px;
    border-radius: 8px;
    @media (min-width: $tablet) {
      max-width: 450px;
    }

    .payment-success-title {
      margin-bottom: 12px;
      color: #000;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
    }

    .payment-success-desc {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.text-tool-tip-wrapper {
  width: 100%;

  .main-tooltip-text {
    width: 100%;

    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}