@import "../../assets/styles/global";

.drawer_opening_main_button {
  @include resetBtn;
  width: 100%;
  border-top: 1px solid $borderGray;
  background: $pageGray;
  padding: 14px 0;
  @include flexCenter;
  gap: 6px;
  color: $richNavy;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.header-drawer-main-wrapper {
  @include spaceBetween;
  padding: 16px;
  color: $richNavy;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 1px solid $borderGray;

  button {
    @include resetBtn;

    .header-drawer-close_svg-memory {
      path {
        fill: white;
      }
    }
  }
}