@import "../../../assets/styles/global";

.in-celebration-page {
  min-height: 100vh;
  background-size: cover;
  @include flexCenter;
}

.in-celebration-of-page__background-small {
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat url("../../../assets/images/img/inCelebrationBeckSmall.png");
  background-size: cover;
}

.in-celebration-of-page__background-big {
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat url("../../../assets/images/img/inCelebrationBeck.png");
  background-size: cover;
}

.in-celebration-box {
  width: 60%;
  height: 100vh;
  position: relative;
  margin-left: auto;
  overflow: hidden;
  border-radius: 48px 0 0 48px;
  background: $backgroundGray;
  z-index: 99;

  @media (max-width: $desktop) {
    width: 100%;
    border-radius: 0;
    z-index: 1;
  }
}

.in-celebration-content {
  position: absolute;
  width: 100%;
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.in-celebration-form-wrapper {
  height: auto;
  max-height: 70vh;
  overflow: auto;
  width: 100%;
  border-radius: 8px;
  background: $white;
  margin: 0 auto;

  @media (max-width: $tablet) {
    max-height: 66vh;
  }

  &::-webkit-scrollbar {
    display: block !important;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  .in-celebration-form-header {
    border-bottom: 1px solid $borderGray;
    padding: 24px 16px;

    @media (min-width: $tablet) {
      padding: 24px 32px;
    }

    .in-celebration-form-header-title {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }

    .in-celebration-form-header-desc {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 4px;
    }

    .in-celebration-form-header-chips-block {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 16px;

      .in-celebration-form-header-chip {
        width: 100%;
        color: $richNavy;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 8px;
        padding: 8px;
        background: $pageGray;
      }
    }
  }

  .in-celebration-form-content {
    //max-height: 45vh;
    padding: 16px;
    //overflow: auto;

    @media (min-width: $tablet) {
      padding: 16px 32px;
    }


    .celebration-input {

      .error-message {
        color: $red2;
      }

      .input-main-label-header {
        color: $richNavy;

        .input-main-label-header-required {
          color: $richNavy;
        }

        &.errorLabel {
          color: $red2;

          .input-main-label-header-required {
            color: $red2;
          }
        }
      }


      .MuiInputBase-root {
        color: $richNavy;

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 2px solid $richNavy;
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border: 2px solid $richNavy;
            }
          }
        }

        &.Mui-error {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $red2;
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid $red2;
            }
          }

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border: 2px solid $red2;
            }

            &:hover {
              .MuiOutlinedInput-notchedOutline {
                border: 2px solid $red2;
              }
            }
          }
        }


        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $richNavy;
          }
        }

        input {
          color: $richNavy;
        }


        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $richNavy;
        }

        .MuiInputAdornment-positionEnd {
          svg {
            fill: $white;
          }
        }
      }
    }


    .celebration-textarea {
      .textarea-main-label-header {
        color: $white;

        .textarea-main-label-header-required {
          color: $white;
        }

        &.errorLabel {
          color: $red2;

          .textarea-main-label-header-required {
            color: $red2;
          }
        }
      }

      .textarea {
        background: transparent;
        color: $white;
        border: 1px solid $borderGray;

        &:focus {
          border: 2px solid $borderGray;
        }

        &.textarea-error {
          border: 1px solid $red2;

          &:hover {
            border: 1px solid $red2;
            outline: 1px solid $red2;
          }

          &:focus {
            border: 1px solid $red2;
            outline: 1px solid $red2;
          }
        }
      }
    }

    .in-celebration-form-uploader {
      .file-uploader {
        background: $pageGray;

        .upload-info-box {
          color: $richNavy;

          .drag-drop-text {
            color: $richNavy;

            span {
              color: $blue2;
            }
          }

          .information-box {
            color: $richNavy;
          }
        }
      }
    }
  }

  .memory-button {
    width: 100%;
    background: $blue2;

    &:hover {
      background: $blue2;
    }
  }

  .choose-charity-search {
    width: 100%;
    background: $pageGray;
    margin-bottom: 12px;

    input {
      background: $pageGray;
    }
  }

  .warning_split_agree {
    @include alignCenter;
    gap: 11px;
    padding: 4px 11px;
    border-radius: 4px;
    background: rgba(230, 57, 70, 0.07);

    svg {
      min-width: 24px;
      min-height: 24px;
    }

    .warning_split_agree-text {
      color: #000;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}


//DeceasedInformation

.deceased-information-wrapper {
  max-width: 654px;
}

//EventInfo

.event-info-wrapper {
  max-width: 654px;
}

//ChooseCharity
.choose-charity-celebration-wrapper {
  max-width: 735px;
  margin: 32px auto;

  .in-celebration-form-content {
    padding: 16px;
  }

  .choose-charity-content {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    gap: 12px;

    .choose-charity-single-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding: 16px;
      background: $pageGray;

      .choose-charity-single {

        .img-title-block {
          display: flex;
          align-items: center;
          gap: 8px;

          .img-block {
            width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .choose-charity-title {
            color: $richNavy;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration-line: underline;
          }
        }

        .choose-charity-description-block {
          margin-top: 8px;

          .choose-charity-description {
            color: $richNavy;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  .request-new-charity {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px dashed $buttonBeck;
    background: $pageGray;
    padding: 16px;
    margin-top: 16px;
    cursor: pointer;

    .request-new-charity_svg-title {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        path {
          fill: $buttonBeck;
        }
      }

      .request-new-charity-title {
        color: $buttonBeck;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .request-new-charity-text {
      max-width: 524px;
      color: $richNavy;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

//ConnectBankAccountUser
.ConnectBankAccount-wrapper {
  max-width: 654px;
}

//Charity Details
.charity-details-in-celebration-wrapper {
  max-width: 654px;

  .in-celebration-form-header-desc {
    max-width: 511px;
  }

  .in-celebration-form-content {
    padding: 32px 32px 16px;
  }

  .charity-details-buttons-block {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px 32px 24px;
    border-top: 1px solid $borderGray;

    .charity-details-cancel {
      width: 100%;
      background: transparent;
      border: 1px solid $borderGray;

      p {
        color: $richNavy;
      }
    }

    .charity-details-submit {
      width: 100%;
    }
  }
}

//LOGIN
.in-celebration-form-login {
  width: 100%;
  max-width: 591px;

  .in-celebration-login-wrapper {
    width: 100% !important;

    .auth-modal-headers {
      padding: 24px 0;

      p {
        color: $richNavy;
      }
    }

    .reset-or-log {
      p {
        color: $richNavy;
      }

      button {
        color: $blue2;
        text-decoration-line: underline;
      }
    }

    .terms-of-use {
      color: $richNavy;

      a {
        color: $richNavy;
      }
    }
  }

  .create-account-wrapper-in-celebration {

    .auth-modal-headers {
      display: none;
    }
  }
}

//DISTRIBUTE
.distribute-wrapper-celebration {
  max-width: 735px;

  .chips-and-info-wrapper {
    border-radius: 8px;
    background: $pageGray;
    padding: 8px 16px;
    margin-top: 16px;

    .chips-and-info-title {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 36px;
    }

    .chips-block {
      width: 100%;
      display: flex;
      gap: 12px;
      align-items: center;

      .single-chips {
        width: 100%;
        color: #000624;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 8px;
        background: $white;
        padding: 8px;
      }
    }
  }

  .distribute-fund-types-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 0 8px;

    .distribute-fund-types-single {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid $borderGray;

      .distribute-fund-types_percents {
        .distribute-fund-types-text_desc {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .distribute-fund-types-text, .distribute-fund-types-desc {
            color: $richNavy;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }

          .distribute-fund-types-text {
            font-weight: 700;
          }
        }

        .distribute-fund_percents {
          @include alignCenter;
          gap: 8px;
          margin: 12px 0;

          .distribute-fund_single-percent {
            @include flexCenter;
            width: calc(100% / 4 - 2px);
            border-radius: 16px;
            background: $pageGray;
            cursor: pointer;
            padding: 6px 0;

            &:hover {
              background: $buttonBeck;
              color: $white;
            }

            &.single-percent_active {
              background: $buttonBeck;
              color: $white;
            }
          }
        }

        .distribute-fund-other_percent {
          .other_percent-mask-wrapper {
            display: flex;
            border-radius: 16px;

            .other_percent-input {
              width: 100%;

              input {
                height: 36px;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                border-radius: 16px 0 0 16px;
                border: 1px solid $borderGray;
                padding: 6px 0 6px 24px;

                &:focus {
                  border: 1px solid $borderGray;
                  outline: none;
                }
              }
            }

            .percent {
              @include flexCenter;
              border-top-right-radius: 16px;
              border-bottom-right-radius: 16px;
              border-top: 1px solid $borderGray;
              border-bottom: 1px solid $borderGray;
              border-right: 1px solid $borderGray;
              padding: 6px 24px;
            }
          }

          .distribute-fund-other_percent-text {
            @include flexCenter;
            width: 100%;
            color: $richNavy;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            border-radius: 16px;
            background: $pageGray;
            padding: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }
}


//PersonalDetailsUser

.personal-details-wrapper {
  max-width: 654px;
  margin: 32px auto;
}

//SeeYourEvent
.see-your-event-wrapper {
  max-width: 455px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px;

  @media (min-width: $tablet) {
    gap: 24px;
    padding: 24px 16px;
  }

  .see-your-event-title_desc {
    .see-your-event-title, .see-your-event-desc {
      color: $richNavy;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    .see-your-event-title {
      margin-bottom: 16px;
    }

    .see-your-event-desc {
      max-width: 319px;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      margin: 0 auto;
    }
  }

  .celebration-button {
    width: 100%;
  }
}

//CharityCreated

.charity-created-wrapper {
  max-width: 482px;

  .see-your-event-title_desc {
    .see-your-event-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @media (min-width: $tablet) {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
      }
    }

    .see-your-event-desc {
      max-width: 100%;
    }
  }

  .charity-created-buttons_block {
    width: 100%;
    display: flex;
    gap: 16px;

    .celebration-button-cancel {
      width: 100%;
      background: $white;
      border: 1px solid $borderGray;

      p {
        color: $richNavy;
      }
    }
  }
}

//Steper

.in-celebration-footer-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid $borderGray;
  background: $white;
  padding: 16px;
  z-index: 9;

  @media (min-width: $tablet) {
    padding: 16px 40px;
  }

  .in-celebration-footer-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;


    .in-celebration-footer-svg_step {
      display: flex;
      align-items: center;
    }

    .in-celebration-footer-steps {
      color: $darkGray;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding: 12px 16px;
    }

    .in-celebration-footer-buttons {
      display: flex;
      align-items: center;
      align-self: end;
      gap: 16px;

      @media (min-width: $tablet) {
        border: none;
        padding: 0;
      }

      .in-celebration-footer-svg {
        @include flexCenter;
        width: 48px;
        height: 48px;
        border: 1px solid $borderGray;
        border-radius: 8px;
        cursor: pointer;

        @media (min-width: $tablet) {
        }

        svg {
          path {
            stroke: $darkGray;
          }
        }
      }


      .in-celebration-footer-button-confirm {
        width: 242px;

        @media (max-width: $tablet) {
          width: 170px;
        }

        &:disabled {
          background: $buttonBeck;
        }
      }
    }
  }
}



