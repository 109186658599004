@import '../../../assets/styles/global.scss';


.stripe-card-box {
  width: 100%;
  //max-width: 340px;
  //@media (min-width: $tablet) {
  //  max-width: 540px;
  //  width: 540px;
  //}
}


.card-title-box {
  @include spaceBetween;
  margin-bottom: 19px;

  div {
    @include alignCenter;
    gap: 11px;

    .card-title-box-svg {

      path {
        fill: $borderGray;
      }
    }

    p {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

}

.checkoutForm-wrapper {
  .MuiRadio-root {
    padding: 0;
    color: $blue2;
  }

  .MuiRadio-root.Mui-checked {
    color: $blue2;
  }

  .checkoutForm-label {
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    .checkoutForm-input {
      width: 100%;
      font-size: 16px;
      border-radius: 8px;
      border: 1px solid $darkGray;
      padding: 12px;
      background: white;
      margin-top: 8px;

      &:hover {
        outline: 1px solid black;
      }

      &:active, &:focus {
        outline: 1px solid black;
      }

      &.StripeElement--focus {
        outline: 1px solid black
      }

      &.checkoutForm-input-memory {
        background: $chipBack3;
        border: 1px solid $darkGray;
        color: $borderGray;

        &.checkoutForm-input-error {
          border: 1px solid $red2;

          &.StripeElement--focus {
            outline: 1px solid $red2;
          }

          &:hover {
            outline: 1px solid $red2;
          }
        }
      }

    }

    .checkoutForm-input-error {
      border: 1px solid $red;

      &.StripeElement--focus {
        outline: 1px solid $red;
      }

      &:hover {
        outline: 1px solid $red;
      }
    }
  }

  .cardholder-block, .credit-card-number,
  .credit-card-expiry-date, .credit-card-cvv {
    width: 100%;
  }

  .expiry-date-cvv {
    display: flex;
    flex-direction: column;
    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 32px;
    }
  }

  .checkbox-text-block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    @media (min-width: $tablet) {
      margin-bottom: 32px;
    }

    .checkbox-text {
      color: black;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      a {
        margin-left: 6px;
      }

      .checkbox-text-link {
        color: black;
        font-weight: 600;
        text-decoration-line: underline;
      }
    }
  }

  .generate-report-button {
    margin: 40px 0 16px;
  }

  .card-errors {
    color: $red;
    font-size: 12px;
    font-weight: 400;
    min-height: 18px;
    margin-left: 15px;
    margin-top: 2px;
  }

  &.checkoutForm-memory-wrapper {
    .MuiRadio-root, .MuiRadio-root.Mui-checked {
      padding: 0;
      color: $white;
    }
  }
}

.card-action-btns {
  display: flex;
  gap: 16px;
}
