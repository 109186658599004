@import "../../assets/styles/global";

.main-button {
  @include flexCenter;
  gap: 8px;
  background: $buttonBeck;
  border: none;
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;
  transition: all .2s;
  padding: 12px 16px;
  height: 48px;

  @media (min-width: $tablet) {
    padding: 12px 36px;
  }

  &:hover {
    background: $buttonBeckHover;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    color: $white;
    background: $disableGray;
  }

  p {
    color: $white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .flex-all-center {
    @include alignCenter;
    gap: 8px;
  }
}

.back-btn {
  @include alignCenter;
  @include resetBtn;
  gap: 10px;

  p {
    color: $richNavy;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.close-modal-btn {
  @include flexCenter;
  @include resetBtn;
  width: 28px;
  height: 28px;
  border: none;
}

.close-btn {
  @include resetBtn;
  color: $richNavy;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid $borderGray;
  padding: 11px 12px;
}

.edit-btn-box {
  @include alignCenter;
  @include resetBtn;
  gap: 8px;
  padding: 6px 16px;
  color: $blue2;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  p {
    display: none;
  }

  @media (min-width: $tablet) {
    padding: 6px 24px;
    p {
      display: block;
    }
  }
}

.delete-btn-box {
  @include resetBtn;
  @include alignCenter;
  gap: 8px;
  color: $red;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.delete-btn-mobile-box {
  @include resetBtn;
  @include flexCenter;
  gap: 8px;
  color: $red;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #E0E1E2;
  padding: 11px 0;
  width: 100%;
}


.share-popper-wrapper {
  width: 100%;

  .share-btn {
    @include flexCenter;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: none;
    gap: 8px;
    border-radius: 8px;
    color: $richNavy;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    &.share-btn-with_border {
      border: 1px solid $borderGray;
    }

    p {
      color: $richNavy;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.share-select-paper-box {
  .MuiBox-root {
    margin-top: 0;
    padding: 16px;
    border-radius: 8px;
    background: #FFF;
    border: 1px solid $borderGray;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);

    @media (min-width: $tablet) {
      padding: 24px;
      margin-top: 16px;

    }
  }

  .share-popper-box {

    .share-title-close-button {
      @include spaceBetween;
      margin-bottom: 16px;

      @media (min-width: $tablet) {
        margin-bottom: 11px;
      }

      .share-close-button {
        @include resetBtn;
        padding: 6.4px;

        @media (min-width: $tablet) {
          display: none;
        }

        svg{
          width: 100%;
          height: 100%;
          filter: brightness(0) saturate(100%) invert(15%) sepia(16%) saturate(1297%) hue-rotate(161deg) brightness(96%) contrast(89%);
        }
      }
    }

    .share-title {
      color: #000;
      font-family: Outfit, sans-serif;
      font-size: 18px;
      font-weight: 500;
    }

    .share-social-wrapper {
      @include column;
      gap: 15px;
      margin: 11px 0
    }

    .share-social-box {
      @include flexCenter;
      gap: 18px;
      flex-direction: column;
      @media (min-width: $tablet) {
        flex-direction: row;
      }
    }

    .social-box {
      @include alignCenter;
      gap: 16px;
      border-radius: 4px;
      padding: 12px 16px;
      background: $backgroundGray;
      width: 100%;
      cursor: pointer;
      @media (min-width: $tablet) {
        width: 200px;
      }

      p {
        color: #000;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .share-and-copy-link {
      @include flexCenter;
      gap: 11px;

      .share-link {
        border-radius: 4px;
        background: $backgroundGray;
        width: 100%;
        max-width: 355px;
        padding: 12px 16px;
        overflow: scroll;

        input {
          background: transparent;
          border: none;
          outline: none;
          width: 100%;
          overflow: hidden;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .copy-link-btn {
        display: flex;
        padding: 12px 16px;
        border-radius: 4px;
        background: $backgroundGray;
        border: none;
        cursor: pointer;
      }
    }

    .qr-code_down_button {
      @include flexCenter;
      gap: 11px;
      margin-top: 11px;
      padding: 6px 16px;

      .qr-code-imageBlock {
        width: 45px;
        min-width: 45px;
        height: 45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .download-qr-code-button {
        width: 227px;
        height: 36px;
        padding: 8px 16px;
        background: $blue2;

        @media (min-width: $tablet) {
          width: 334px;
        }

        .button-start-adornment {
          width: 24px;
          height: 24px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.status-active-inactive {
  @include flexCenter;
  border: none;
  padding: 4px 10px;
  gap: 4px;
  border-radius: 4px;
  width: 90px;
  height: 30px;

  p {
    color: $richNavy;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.transfer-status-box {
  @include alignCenter;
  width: fit-content;
  border: none;
  padding: 4px 10px;
  gap: 4px;
  border-radius: 4px;
  height: 30px;

  p {
    color: $richNavy;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
  }
}

.transfer-status-box-messages {
  @include alignCenter;
  width: fit-content;
  border: none;
  padding: 4px 10px;
  gap: 4px;
  border-radius: 4px;
  height: 30px;
  background: #FFE2DD;

  p {
    color: #5D1715;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    text-decoration-line: underline;
  }

  &.no-account {
    background: $borderGray;

    p {
      color: $richNavy;
    }
  }

  &.success-message {
    background: $backGreen;

    p {
      color: $richNavy;
    }
  }

  &.error-account {
    background: #FFE2DD;

    p {
      color: #5D1715;
    }
  }

  svg {
    filter: brightness(0) saturate(100%) invert(9%) sepia(57%) saturate(2912%) hue-rotate(348deg) brightness(110%) contrast(92%);
  }
}

.active-btn {
  background: #DBEDDB;
}

.inactive-btn {
  background: $borderGray;
}

.status-select-paper-box {
  .MuiBox-root {
    width: 150px;
    margin-top: 4px;
    padding: 16px 0;
    border-radius: 8px;
    background: #FFF;
    border: 1px solid $borderGray;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
  }


}