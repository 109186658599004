@import "../../../assets/styles/global";

.transfers-page {
  @include pageWrapper;

  .tab-and-search {
    @include spaceBetween;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 32px 0 0;

    @media (min-width: $tablet) {
      gap: 24px;
      padding: 45px 0 0;
    }
    @media (min-width: $desktop) {
      flex-direction: row;
      align-items: center;
      padding: 52px 0 0;
      gap: 0;
    }
  }

  .transfers-table-wrapper {
    .table-wrapper-desktop {
      .table-container {
        height: 69vh;
      }
    }

    .full-table-accordion-wrapper {
      .accordion-table-body {
        height: 58vh;
        overflow: auto;
      }
    }

    .table-wrapper-mobile {
      .table-wrapper-mobile-wrapper {
        height: 66vh;
      }
    }
  }
}


.transfer-mobile-card-box {
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-top: 1px solid #E0E1E2;
  background: #F4F4F4;
  padding: 14px 0;
  @include flexCenter;
  gap: 6px;
  color: #000624;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

//.detail-box-wrapper {
.MuiDrawer-paper {
  border-radius: 16px 16px 0 0;
}

//}
.drawer-detail-box {
  padding: 16px;

  .drawer-details-head {
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid $borderGray;
    background: $pageGray;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: Outfit, sans-serif;

    span {
      color: $darkGray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    p {
      color: $richNavy;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .drawer-details-body{
    border-radius:  0 0 4px 4px;
    border-bottom: 1px solid $borderGray;
    background: $pageGray;
    padding: 9px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: Outfit, sans-serif;
  }

  .detail-box{
    display: flex;
    align-items: flex-start;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 8px;
    background: #FFF;
    span{
      color: $darkGray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
    }
    p{
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      width: 100%;
    }
  }
}

.close-transfer-modal-header {
  @include spaceBetween;
  padding: 16px;
  color: #2A374E;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 1px solid #E0E1E2;
}

.drawer-detail-footer{
  padding: 0 16px 16px;
  width: 100%;

  & .main-button{
    width: 100%;
  }
}

.drawer-detail-error-box{
  border-radius: 4px;
  background: rgba(255, 226, 221, 0.40);
  padding: 8px 16px;
  @include flexStart;
  justify-content: flex-start;
  gap: 10px;
  color: $red2;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}

.accordion-detail-title{
  color: $darkGray;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.accordion-detail-box{
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  margin: 8px 0;
}
.accordion-detail-item{
  border-radius: 8px;
  background: $pageGray;
  padding: 8px 16px;
  font-family: Outfit, sans-serif;
  @include flexStart;
  justify-content: flex-start;
  gap: 4px;

  span{
    color: $darkGray;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }
  p{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}