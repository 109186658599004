@import "../../assets/styles/global";

//AccordionRadio

.accordion-radio-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .accordion-radio-box {
    border-radius: 8px !important;
    border: 1px solid $borderGray !important;
    background: $white !important;
    box-shadow: none !important;
    padding: 0 !important;

    &:before {
      display: none !important;
    }

    &.Mui-expanded {
      margin: 0 !important;

      & .accordion-radio-summary-wrapper {
        padding-bottom: 0 !important;
      }

      & .accordion-radio-summary-no-expanded {
        padding: 24px !important;
      }
    }


    .accordion-radio-summary-wrapper {
      padding: 24px !important;
      min-height: auto !important;

      .MuiAccordionSummary-content {
        margin: 0 !important;

        .accordion-radio-summary-box {
          width: 100%;
          @include spaceBetween;

          .accordion-radio-summary-icon_title {
            @include alignCenter;
            gap: 11px;
          }

          .accordion-radio-title {
            color: #000;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .MuiRadio-root {
            padding: 0;
            color: $blue2;
          }

          .MuiRadio-root.Mui-checked {
            color: $blue2;
          }
        }
      }
    }

    .accordion-radio-details-wrapper {
      padding: 21px 24px 24px !important;
    }
  }
}


