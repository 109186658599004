@import "../../../assets/styles/global";

.fundraiser-details-page {
  @include pageWrapper;

  .event-details-back-btn {
    margin-top: 39px;

    @media (min-width: $tablet) {
      margin-top: 45px;
    }

    @media (min-width: $desktop) {
      margin-top: 36px;
    }
  }

  .charity-details-tab {
    @include spaceBetween;
    padding: 24px 0 8px 0;
    @media (min-width: $desktop) {
      padding: 32px 0 24px 0;
    }
  }

  .fundraiser-detail-screen-wrapper {
    @include column;
    gap: 16px;
  }


  .charity-status-mobile-box {
    padding-top: 16px;
    margin-top: 16px;
    width: 100%;
    border-top: 1px solid $borderGray;

    .main-button {
      width: 100%;
    }

    @media (min-width: $tablet) {
      display: none;
    }

    .status-active-inactive {
      width: 100%;
      height: 40px;
    }
  }


  .fundraiser-detail-description {
    border-top: 1px solid $borderGray;
    padding-top: 16px;
    margin-top: 16px;

    p {
      color: $darkGray;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
  ;

    span {
      color: $richNavy;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .charities-events-table-wrapper {
    .table-wrapper-desktop {
      .table-container {
        height: 61vh;

        @media (max-width: $desktop) {
          height: 57vh
        }
      }
    }

    .table-wrapper-mobile {
      .table-wrapper-mobile-wrapper {
        height: 60vh;
      }
    }
  }
}

.fundraiser-detail-actions {
  @include alignCenter;
  gap: 16px;

  @media (max-width: $tablet) {
    @include flexCenter;
    border-radius: var(--Corner-Radius-S, 8px);
    background: var(--BG, #F4F4F4);
    padding: 19px 16px;
  }

  .main-button {
    height: 36px;
  }

  .fundraiser-detail-refresh_button {
    @include resetBtn;
    @include flexCenter;
    gap: 8px;
    color: $buttonBeck;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .charity-status-box {
    display: none;
    @media (min-width: $tablet) {
      display: block;
    }
  }

  &.desktop {
    display: flex;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media (max-width: $tablet) {
      display: flex;
    }
  }
}


.publish-modal {
  max-width: 310px;
  width: 100%;
  @media (min-width: $tablet) {
    max-width: 412px;
  }

  @include column;
  gap: 16px;

  .publish-modal-title {
    color: $richNavy;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .publish-modal-desc {
    color: $richNavy;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .publish-modal-no-btn {
    @include resetBtn;
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
  }

  .main-button {
    width: 150px;
  }
}

.edit-fundraiser-inputs-box {
  @include flexStart;
  flex-direction: column;
  gap: 0;
  @media (min-width: $tablet) {
    flex-direction: row;
    gap: 16px;
  }

  .accordion-wrapper {
    width: 100%;
  }

  .input-main-wrapper {
    width: 100%;
  }
}

.edit-fundraiser-save-cancel {
  @include flexEnd;

  .default-actions-box {
    width: 410px;
  }
}


.charity-bank-account {
  @include flexCenter;
  margin-top: 70px;

  .connect-bank-account {
    @include flexCenter;
    flex-direction: column;
    width: 100%;
    max-width: 624px;
    height: 325px;
    padding: 16px;
    gap: 24px;
    border-radius: 8px;
    background: #FFF;
    @media (min-width: $tablet) {
      padding: 24px;
    }

    .connect-bank-account-info {
      text-align: center;

      p {
        color: #000;
        font-size: 22px;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 16px;
        @media (min-width: $tablet) {
          font-size: 24px;
        }
      }

      span {
        color: rgba(34, 34, 34, 0.80);
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}


.charity-bank-account-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: $tablet) {
    gap: 16px;
  }

  .nonprofit-details {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: $tablet) {
      gap: 16px;
    }
  }

  .personal-details {
    .detail-page-title {
      margin-bottom: 16px;
    }
  }

  .nonprofit-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $desktop) {
      flex-direction: row;
    }
  }

  .nonprofit-details-box {
    width: 100%;
    border-radius: 8px;
    border: 1px solid $borderGray;
    padding: 16px;
  }

  .nonprofit-details_title {
    color: $darkGray;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .connected-bank-wrapper {
    @include spaceBetween;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-start;
    margin-top: 24px;
    padding: 24px 16px;
    border-radius: 8px;
    border: 1px solid #BEBEBE;
    background: #FFF;

    .bank-information-box {
      @include flexStart;
      gap: 12px;

      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .bank-name {
        color: #292D32;
        font-size: 18px;
        font-weight: 700;
      }

      .bank-current-account {
        color: #9F9F9F;
        font-size: 14px;
        font-weight: 400;
      }

      .bank-routing {
        color: #626262;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .connect_bank_buttons {
      display: flex;
      gap: 12px;

      .connect_bank_delete-button, .connect_bank_update-button {
        @include resetBtn;
        @include flexCenter;
        text-align: center;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        gap: 8px;
        padding: 6px 16px;
        border-radius: 4px;
      }

      .connect_bank_delete-button {
        color: $richNavy;

        svg {
          path {
            fill: $richNavy;
          }
        }
      }

      .connect_bank_update-button {
        background: $buttonBeck;
        color: $white;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
}

//Requirements

.requirements-wrapper {
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  border: 1px #FCE2DF;
  background: #FFF4F4;
  border-radius: 4px;

  .requirements-error-message {
    color: $richNavy;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    span {
      color: $red2
    }
  }
}

.settings-connect-bank-account-box {
  .settings-connect-bank-account-header {
    padding: 16px;
    border-bottom: 1px solid $borderGray;

    @media (min-width: $tablet) {
      padding: 24px 32px;
    }

    .settings-connect-bank-account-title {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      margin-bottom: 16px;

      @media (min-width: $tablet) {
        margin-bottom: 36px;
      }
    }

    .connect-bank-account-steps-wrapper {
      @include alignCenter;
      gap: 10px;
      margin: 0 0 0 25px;

      .connect-bank-account-single-step-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &.last-step {
          width: fit-content;
        }

        .single-step_and_dots {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;

          .connect-bank-account-single-step {
            @include flexCenter;
            width: 40px;
            height: 40px;
            color: $richNavy;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            padding: 10px;
            border-radius: 4px;
            background: $pageGray;
            cursor: pointer;

            @media (max-width: $tablet) {
              width: 24px;
              height: 24px;
            }

            &.active-tab {
              background: $blue2;
              color: $pageGray;
            }
          }

          .connect-bank-account-step-dots {
            width: 100%;
            height: 1px;
            border: 1px dashed $richNavy;
          }
        }


        .connect-bank-account-single-step-text {
          color: $richNavy;
          font-family: Outfit, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          white-space: nowrap;
          margin-left: -25px;

          @media (max-width: $tablet) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.settings-connect-modal-wrapper {
  max-width: 654px;
  width: 80vw;

  .settings-connect-modal-header {
    padding: 16px;
    border-bottom: 1px solid $borderGray;

    @media (min-width: $tablet) {
      padding: 16px 32px;
    }

    .settings-connect-modal-title {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;

      @media (max-width: $tablet) {
        font-size: 20px;
      }
    }
  }

  .settings-connect-modal-body {
    padding: 16px;
    max-height: 55vh;
    overflow: auto;

    @media (min-width: $tablet) {
      padding: 32px;
    }
  }

  .settings-connect-modal-footer {
    display: flex;
    gap: 18px;
    padding: 16px;
    border-top: 1px solid $borderGray;

    button {
      width: 100%;
    }

    @media (min-width: $tablet) {
      padding: 16px 32px;
    }
  }
}









