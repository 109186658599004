@import "../../assets/styles/global";

.privacy-page {
  @include pageWrapper;
  font-family: "Open Sans", sans-serif;

  .privacy-title {
    color: #2B273C;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 8px 0 16px 0;
    @media (min-width: $tablet) {
      margin: 32px 0;
    }
  }

  .flex-gap10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .sub_list-box {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 10px;

    li::marker {
      color: $textColor;
    }
  }

  .privacy-box {
    border-radius: 12px;
    background: #FFF;
    padding: 24px;
    @media (min-width: $tablet) {
      padding: 32px;
    }

    .bold-text {
      color: $textColor;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    .main-text {
      color: $textColor;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}