@import "../../../assets/styles/global";

.fundraiser-page {
  @include pageWrapper;

  .tab-and-search {
    @include spaceBetween;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 0;
    gap: 16px;

    @media (min-width: $tablet) {
      gap: 24px;
    }
    @media (min-width: $desktop) {
      flex-direction: row;
      align-items: center;
      padding: 24px 0;
      gap: 0;
    }
  }

  .fundraiser-table-wrapper {
    .table-wrapper-desktop {
      .table-container {
        height: 55vh;

        @media (max-width: $desktop) {
          height: 52vh
        }
      }
    }

    .table-wrapper-mobile {
      .table-wrapper-mobile-wrapper {
        height: 57vh;
      }
    }
  }
}

.create-fundraiser-page {
  padding: 104px 0 100px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  @media (min-width: $tablet) {
    padding: 104px 40px;
  }
  @media (min-width: $desktop) {
    padding: 104px 100px 100px;
  }
}


.create-fundraiser-action-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  border-top: 1px solid #E0E1E2;
  background: $white;
  height: 80px;

  .create-fundraiser-action-box {
    @include containerWithPadding;

    button {
      min-width: 160px;
    }

    .save-as-drat {
      @include resetBtn;
      height: 48px;
      padding: 12px 24px;
      color: $richNavy;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.create-fundraiser-form {
  max-width: 652px;
  width: 100%;
  margin: 0 auto;

  .create-fundraiser-back {
    padding: 16px 32px;
    @media (min-width: $tablet) {
      padding: 24px 32px;
    }
  }

  .create-fundraiser-form-box {
    border-radius: 0;
    background: $white;
    width: 100%;

    @media (min-width: $tablet) {
      border-radius: 8px;
    }
  }

  .create-fundraiser-head {
    @include spaceBetween;
    padding: 16px;
    border-bottom: 1px solid $borderGray;
    @media (min-width: $tablet) {
      padding: 16px 32px;
    }

    button {
      @include resetBtn;
    }

    .create-fundraiser-title {
      color: $richNavy;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .create-fundraiser-body {
    width: 100%;
    padding: 16px;
    @media (min-width: $tablet) {
      padding: 32px;
    }
  }

  .create-charity-inputs-box {
    @include alignCenter;
    flex-direction: column;
    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 16px;
    }

    .accordion-wrapper {
      width: 100%;
      @media (min-width: $tablet) {
        width: 60%;
      }
    }

    .input-main-wrapper {
      width: 100%;
      @media (min-width: $tablet) {
        width: 40%;
      }
    }
  }
}

.fundraiser-details-page {
  @include pageWrapper;

  .fundraiser-details-tab {
    padding: 32px 0 24px 0;
  }
}
