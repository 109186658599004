@import "../../../assets/styles/global";


.charities-page {
  @include pageWrapper;

  .tab-and-search {
    @include spaceBetween;
    padding: 17px 0 16px;

    @media (min-width: $tablet) {
      padding: 18px 0 24px;
    }

    @media (min-width: $desktop) {
      padding: 24px 0 0;
    }
  }

  .search-box {
    height: 48px;
  }

  .charities-table-wrapper {
    .table-wrapper-desktop {
      .table-container {
        height: 57vh;

        @media (max-width: $desktop) {
          height: 52vh
        }
      }
    }

    .table-wrapper-mobile {
      .table-wrapper-mobile-wrapper {
        height: 57vh;
      }
    }
  }
}

.create-charity-modal {
  width: 320px;

  @media (min-width: $tablet) {
    width: 514px;
  }
}

.delete-btn{
  @include resetBtn;
  @include flexCenter;
  color: $red2;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}