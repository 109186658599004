@import "../../assets/styles/global";

.terms-and-conditions-page {
  @include pageWrapper;
  font-family: "Open Sans", sans-serif;

  .terms-and-conditions-title {
    color: #2B273C;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 8px 0 16px 0;
    @media (min-width: $tablet) {
      margin: 32px 0;
    }
  }

  .terms-and-conditions-box {
    border-radius: 12px;
    background: #FFF;
    padding: 24px;
    @media (min-width: $tablet) {
      padding: 32px;
    }

    .terms-lists-block {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style-type: decimal;
      list-style-position: inside;
      color: #2A2F35;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      font-weight: 700;

      .terms-lists-item {

      }
    }

    .terms-sub-lists {
      list-style-type: disc;
      list-style-position: inside;
      color: #2A2F35;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
      margin-left: 15px;
    }

    span {
      color: #2A2F35;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
    }

    b {
      font-weight: 700;
    }
  }
}