@import "../../../assets/styles/global";

.in-memory-of-page {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  @include flexCenter;
  position: relative;


  .in-memory-of-page__background-small {
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background: no-repeat url("../../../assets/images/img/inMemoryOfBeckSmall.png");
    background-size: cover;
  }

  .in-memory-of-page__background-big {
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background: no-repeat url("../../../assets/images/img/inMemoryOfBeck.png");
    background-size: cover;
  }

  .in-memory-of-box {
    width: 60%;
    height: 100vh;
    position: relative;
    margin-left: auto;
    overflow: hidden;
    border-radius: 48px 0 0 48px;
    background: $backgroundGray;


    .in-memory-of-content {
      position: absolute;
      width: 100%;
      height: fit-content;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
}

.in-memory-form-wrapper {
  max-height: 70vh;
  height: auto;
  overflow: auto;
  width: 100%;
  border-radius: 8px;
  background: $beckBlack;
  margin: 0 auto;

  @media (max-width: $tablet) {
    max-height: 66vh;
  }


  &::-webkit-scrollbar {
    display: block !important;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $beckBlack;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $beckBlack2;
    border-radius: 10px;
  }

  .in-memory-form-header {
    border-bottom: 1px solid $borderBottom;
    padding: 24px 16px;

    @media (min-width: $tablet) {
      padding: 24px 32px;
    }

    .in-memory-form-header-title {
      color: $white;
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }

    .in-memory-form-header-desc {
      color: $white;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 4px;
    }

    .in-memory-form-header-chips-block {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 16px;

      @media (max-width: $tablet) {
        flex-direction: column;
      }

      .in-memory-form-header-chip {
        width: 100%;
        color: $white;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 8px;
        padding: 8px;
        background: $chipGray;
      }
    }
  }

  .in-memory-form-content {
    //max-height: 55vh;
    padding: 16px;
    overflow: auto;

    @media (min-width: $tablet) {
      padding: 24px 32px;
    }


    .memory-input {
      .error-message {
        color: $red2;
      }

      .input-main-label-header {
        color: $white;

        .input-main-label-header-required {
          color: $white;
        }

        &.errorLabel {
          color: $red2;

          .input-main-label-header-required {
            color: $red2;
          }
        }
      }

      .date-input-title {
        color: $borderGray !important;
      }

      .react-datepicker__input-container {

        svg {
          path {
            fill: $borderGray !important;
          }
        }

        button {
          .date-input-placeholder {
            color: #C5C5C5 !important;
          }

          .date-input-value {
            color: $borderGray !important;
          }

          &::after {
            color: $borderGray !important;
          }
        }

        .date-picker-box {
          border: 1px solid $borderGray !important;
        }
      }

      &.date-input-box-error {
        .date-input-title {
          color: $red2 !important;
        }

        .date-picker-box {
          border: 1px solid $red2 !important;
        }
      }


      .MuiInputBase-root {
        color: $white;

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 2px solid $white;
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border: 2px solid $white;
            }
          }
        }

        &.Mui-error {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $red2;
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid $red2;
            }
          }

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border: 2px solid $red2;
            }

            &:hover {
              .MuiOutlinedInput-notchedOutline {
                border: 2px solid $red2;
              }
            }
          }
        }


        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $borderGray;
          }
        }

        input {
          color: $borderGray;

          &::placeholder {
            color: #C5C5C5;
          }
        }


        input[type="date"] {
          color: white;
          color-scheme: dark;
        }

        input[type="date"]::-webkit-datetime-edit {
          color: $white;
        }

        input[type="date"]::-webkit-datetime-edit-text {
          color: $white;
        }

        input[type="date"]::-webkit-datetime-edit-month-field,
        input[type="date"]::-webkit-datetime-edit-day-field,
        input[type="date"]::-webkit-datetime-edit-year-field {
          color: $white;
        }


        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $borderGray;
        }

        .MuiInputAdornment-positionStart {
          svg {
            path {
              stroke: $white;
            }
          }
        }

        .MuiInputAdornment-positionEnd {
          svg {
            fill: $white;
          }
        }
      }
    }

    .memory-textarea {
      .textarea-main-label-header {
        color: $white;

        .textarea-main-label-header-required {
          color: $white;
        }

        &.errorLabel {
          color: $red2;

          .textarea-main-label-header-required {
            color: $red2;
          }
        }

      }

      .textarea {
        background: transparent;
        color: $borderGray;
        border: 1px solid $borderGray;

        &::placeholder {
          color: #C5C5C5;
        }

        &:focus {
          border: 2px solid $borderGray;
        }

        &.textarea-error {
          border: 1px solid $red2;

          &:hover {
            border: 1px solid $red2;
            outline: 1px solid $red2;
          }

          &:focus {
            border: 1px solid $red2;
            outline: 1px solid $red2;
          }
        }
      }
    }

    .in-memory-form-uploader {
      .file-uploader {
        background: #161D25;

        .upload-info-box {
          color: $white;

          .drag-drop-text {
            color: $white;

            span {
              color: $white;
            }
          }

          .information-box {
            color: $white;
          }
        }
      }
    }
  }

  .memory-button {
    width: 100%;
    background: $blue2;

    &:hover {
      background: $blue2;
    }
  }
}

//DeceasedInformation

.deceased-information-wrapper {
  max-width: 654px;

  .date-of-birth_death {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 16px;
    }
  }
}

//EventInfo

.event-info-wrapper {
  max-width: 654px;
  max-height: 70vh;
  overflow: auto;
}

//ChooseCharity
.choose-charity-wrapper {
  max-width: 735px;
  margin: 32px auto;

  .in-memory-form-content {
    padding: 16px;
  }

  .choose-charity-content {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    gap: 12px;

    .choose-charity-single-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding: 16px;
      background: #33383D;

      .choose-charity-single {

        .img-title-block {
          display: flex;
          align-items: center;
          gap: 8px;

          .img-block {
            width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .choose-charity-title {
            color: #E0E1E2;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration-line: underline;
          }
        }

        .choose-charity-description-block {
          margin-top: 8px;

          .choose-charity-description {
            color: #E0E1E2;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  .request-new-charity {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px dashed #E0E1E2;
    background: #33383D;
    padding: 16px;
    margin-top: 16px;
    cursor: pointer;

    .request-new-charity_svg-title {
      display: flex;
      align-items: center;
      gap: 8px;

      .request-new-charity-title {
        color: $borderGray;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .request-new-charity-text {
      max-width: 409px;
      color: $borderGray;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

//ConnectBankAccountUser
.ConnectBankAccount-wrapper {
  max-width: 654px;
}

//Charity Details
.charity-details-wrapper {
  max-width: 654px;

  .in-memory-form-content {
    padding: 32px 32px 16px;
  }

  .charity-details-buttons-block {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px 32px;
    border-top: 1px solid $darkGray;

    .charity-details-cancel {
      width: 100%;
      background: transparent;
      border: 1px solid $darkGray;
    }

    .charity-details-submit {
      width: 100%;
      background: $white;

      p {
        color: #1C212D;
      }
    }
  }
}

//LOGIN
.in-memory-form-login {
  width: 100%;
  max-width: 591px;

  .in-memory-login-wrapper {

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 32px $beckBlack inset !important;
      color: white;
      border-radius: 0;
    }


    width: 100% !important;

    .auth-modal-headers {
      padding: 24px 0;

      p {
        color: $white;
      }
    }

    .reset-or-log {
      p {
        color: $white;
      }

      button {
        color: $white;
        text-decoration-line: underline;
      }
    }

    .terms-of-use {
      color: $white;

      a {
        color: $white;
      }
    }
  }

  .create-account-wrapper-in-memory {

    .auth-modal-headers {
      display: none;
    }
  }
}

//DISTRIBUTE
.distribute-wrapper {
  max-width: 735px;

  .chips-and-info-wrapper {
    border-radius: 8px;
    background: $chipBack;
    padding: 8px 16px;
    margin-top: 16px;

    .chips-and-info-title {
      color: $white;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 36px;
    }

    .chips-block {
      width: 100%;
      display: flex;
      gap: 12px;
      align-items: center;

      @media (max-width: $tablet) {
        flex-direction: column;
      }

      .single-chips {
        width: 100%;
        color: $white;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 8px;
        background: $chipBack2;
        padding: 8px;
      }
    }
  }

}

.distribute-fund-types-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0 8px;

  .distribute-fund-types-single {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 8px;
    border: 1px solid $richNavy;

    .distribute-fund-types-text_desc {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .distribute-fund-types-text, .distribute-fund-types-desc {
        color: $richNavy;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .distribute-fund-types-text {
        font-weight: 700;
      }
    }
  }
}


//PersonalDetailsUser

.personal-details-wrapper {
  max-width: 654px;
  margin: 32px auto;
}

//SeeYourEvent
.see-your-event-memory-wrapper {
  max-width: 455px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px;

  @media (min-width: $tablet) {
    gap: 24px;
    padding: 24px 16px;
  }

  .see-your-event-title_desc {
    .see-your-event-title, .see-your-event-desc {
      color: $white;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    .see-your-event-title {
      margin-bottom: 16px;
    }

    .see-your-event-desc {
      max-width: 319px;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      margin: 0 auto;
    }
  }
}

//CharityCreated

.charity-created-memory-wrapper {
  max-width: 482px;

  .see-your-event-title_desc {
    .see-your-event-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;

      @media (min-width: $tablet) {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
      }
    }

    .see-your-event-desc {
      max-width: 100%;
    }
  }

  .charity-created-buttons_block {
    width: 100%;
    display: flex;
    gap: 16px;

    .celebration-button-cancel {
      width: 100%;
      background: transparent;
      border: 1px solid $borderGray;

      p {
        color: $white;
      }
    }
  }
}

//Steper

.in-memory-of-footer-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid $borderGray;
  background: $white;
  padding: 16px;
  z-index: 9;

  @media (min-width: $tablet) {
    padding: 16px 40px;
  }


  .in-memory-of-footer-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;


    .in-memory-of-footer-svg_step {
      display: flex;
      align-items: center;
    }

    .in-memory-of-footer-steps {
      color: $darkGray;
      text-align: center;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding: 12px 16px;
    }

    .in-memory-of-footer-buttons {
      display: flex;
      align-items: center;
      align-self: end;
      gap: 16px;

      @media (min-width: $tablet) {
        border: none;
        padding: 0;
      }

      .in-memory-of-footer-svg {
        @include flexCenter;
        width: 48px;
        height: 48px;
        border: 1px solid $borderGray;
        border-radius: 8px;
        cursor: pointer;
        background: white;

        @media (min-width: $tablet) {
        }

        svg {
          path {
            stroke: $darkGray;
          }
        }
      }

      .in-memory-footer-button-confirm {
        width: 242px;
        background: $buttonBeck;

        @media (max-width: $tablet) {
          width: 170px;
        }
      }
    }
  }
}
