@import "../../../assets/styles/global";

.event-details-details-page {
  padding: 88px 0 80px;

  @media (min-width: $tablet) {
    padding: 100px 0 40px;
  }

  @media (min-width: $desktop) {
    padding: 100px 0 120px;
  }

  .event-details-back-btn {
    padding: 16px 0 0;

    @media (min-width: $tablet) {
      padding: 24px 32px;
    }
  }

  .event-details-info-box-desktop {
    display: none;
    @media (min-width: $desktop) {
      display: block;
    }
  }

  .event-details-info-box-mobile {
    display: block;
    @media (min-width: $desktop) {
      display: none;
    }

    .olive-text {
      margin-top: 23px;
    }

    .main-button {
      width: 100%;
      max-width: 435px;
    }
  }

  .event-details-info-box {
    @include flexStart;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    margin-top: 16px;

    @media (min-width: $desktop) {
      flex-direction: row;
      gap: 48px;
    }

    .event-details-info-name {
      color: $richNavy;
      font-family: Outfit,sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;

      @media (min-width: $desktop) {
        font-size: 40px;
      }
    }

    .event-details_img-charities {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (min-width: $tablet) {
      }

      @media (min-width: $desktop) {
        width: 60%;
        max-width: 688px;
      }

    }

    .event-details-image {
      position: relative;
      width: 100%;
      height: 242px;
      border-radius: 16px;
      overflow: hidden;

      @media (min-width: $tablet) {
        height: 400px;
      }

      @media (min-width: $desktop) {
        max-height: 400px;
        height: 400px;
      }

      .event-details-image-slide {
        width: 100%;
        height: 100%;

        .swiper-pagination {
          width: fit-content;
          border-radius: 27px;
          background: rgba(0, 0, 0, 0.45);
          left: 0;
          right: 0;
          margin: 0 auto;
          padding: 4px 8px;
        }

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          background: $borderGray;
        }

        .swiper-pagination-bullet-active {
          background: $buttonBeckHover;
        }
      }

      .event-details-info-type {
        position: absolute;
        top: 22px;
        left: 17px;
        width: fit-content;
        color: $richNavy;
        font-family: Outfit, sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        padding: 9px 16px;
        background: #FFF7D7;
        border-radius: 24px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .created-by-text {
      color: $richNavy;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    .description-wrapper {
      width: 100%;
      padding: 24px 16px;
      background: $white;
      border-radius: 16px;

      .description-title {
        color: $richNavy;
        font-family: Montserrat, sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }

    .event-details-charities-wrapper {
      width: 100%;
      margin-top: 24px;

      .event-details-charities-title {
        color: $richNavy;
        font-family: Montserrat, sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;

        @media (min-width: $tablet) {
          margin-bottom: 24px;
        }
      }

      .event-details-charities-box {
        display: flex;
        flex-direction: column;
        gap: 4px;
        height: auto;
        overflow: auto;
        margin-top: 24px;

        @media (min-width: $tablet) {
          width: 100%;
          margin-top: 16px;
        }

        .event-details-charities_svg {
          @include flexCenter;
          width: 40px;
          height: 40px;
          border-radius: 27px;
          background: $backGreen;
        }
      }

      .event-details-charity {
        @include alignCenter;
        border: none;
        gap: 18px;
        display: flex;
        width: 100%;
        padding: 8px 16px;
        border-radius: 8px;
        background: #FFF;

        p {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-decoration: underline;
        }
      }

      .event-details-charities_see-button {
        @include resetBtn;
        color: $buttonBeck;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-decoration-line: underline;
        margin: 20px 0 0 16px;
      }
    }

    .event-details_info-organizer {
      width: 100%;

      @media (min-width: $desktop) {
        width: 40%;
      }
    }


    .event-details-info-section {
      width: 100%;
      padding: 24px;
      border-radius: 16px;
      background: $white;

      @media (min-width: $desktop) {
        width: 100%;
      }

      .event-details-info_type-displayId {
        @include alignCenter;
        flex-wrap: wrap;
        gap: 12px;

        .event-details-info-displayId {
          @include alignCenter;
          gap: 7px;
          color: $richNavy;
          font-family: Outfit, sans-serif;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          padding: 8px 16px;
          background: #DBEDDB;
          border-radius: 24px;
        }

        .event-details-info_type {
          @include alignCenter;
          gap: 7px;
          color: $richNavy;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          border-radius: 29px;
          background: $yellow;
          padding: 6px 22px;

          .event-details-info_type-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $richNavy;
          }

          &.event-details-info_type-memory {
            background: $richNavy;
            color: $white;

            .event-details-info_type-circle {
              background: $white;
            }
          }
        }
      }

      .event-details-price {
        color: #000;
        font-size: 32px;
        font-weight: 500;
        text-transform: capitalize;
        margin: 16px 0;
      }

      .event-details-info_date {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .event-details-names-block {
          display: flex;
          gap: 5px;

          .event-details-firstName {
            color: $richNavy;
            font-family: Outfit, sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .date-off-celebration {
          color: $darkGray;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          .date-off-celebration-date {
            color: $richNavy;
          }
        }
      }

      .event-details-description-box {
        margin: 16px 0;


        .event-details-description-text {
          color: #000624;
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;

          @media (min-width: $desktop) {
            font-size: 16px;
          }
        }
      }

    }

    .event-details-organizer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 16px;
      gap: 19px;
      border-radius: 8px;
      background: #FFF;


      @media (min-width: $desktop) {
        width: 100%;
        margin-top: 24px;
      }

      .event-details-organizer-title {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
      }

      .event-details-organizer-box {
        @include alignCenter;
        gap: 16px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          object-fit: cover;
        }
      }

      .event-details-organizer-info {
        p {
          color: #000;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }

        span {
          color: $darkGray;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }


    .event-details-donate-and-share {
      @include alignCenter;
      flex-direction: column;
      width: 100%;
      gap: 13px;
      margin-top: 20px;
      background: $white;

      @media (min-width: $desktop) {
        position: static;
        padding: 0;
        border: none;
      }

      .event-details-donate-btn {
        width: 100%;
        max-width: 100%;

        &.event-details-donate-btn-memory {
          background: $richNavy;

          .button-start-adornment {
            svg {
              path {
                fill: $white;
              }
            }
          }

          p {
            color: $white;
          }
        }
      }
    }
  }

  .contributions-wrapper {
    width: 100%;
    padding: 24px 16px;
    border-radius: 16px;
    background: $white;


    @media (min-width: $desktop) {
      padding: 24px;
      margin-top: 24px;
    }

    .contributions-title {
      color: $richNavy;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 24px;

      @media (min-width: $tablet) {
        margin-bottom: 13px;
      }

      @media (min-width: $desktop) {
        margin-bottom: 24px;
      }
    }

    .contributions-search-input {
      display: flex;
      background: $pageGray;
      margin-bottom: 16px;

      @media (min-width: $tablet) {
        display: none;
      }

      input {
        background: $pageGray;
      }

    }

    .contribution-cards-block {
      display: flex;
      flex-direction: column;
      gap: 10px;

    }

    .contributions-full-table {
      .table-wrapper-desktop {
        .MuiTable-root {
          .MuiTableHead-root {
            .MuiTableRow-root {
              .MuiTableCell-root {
                background: $white;
              }
            }
          }

          tbody {
            .MuiTableRow-root {
              background: $pageGray;
            }
          }
        }
      }

    }

    .contributions-accordion {
      .full-table-accordion-wrapper {
        .accordion-table-head {
          background: transparent;
        }

        .MuiAccordionSummary-root {
          background: $pageGray;
          border-radius: 8px;
          overflow: hidden;

          .MuiAccordionSummary-content {

            .accordion-table-row {
              background: transparent;
            }
          }
        }

        .MuiAccordionDetails-root {
          padding: 10px 16px;
          background: $pageGray;
          border-top: 1px solid $borderGray;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}


.all-donor-modal {
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.recent-donors-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $borderGray;
  background: #FFF;
  margin-top: 24px;
  @media (min-width: $tablet) {
    padding: 32px;
  }
  @media (min-width: $desktop) {
    margin-top: 32px;
  }

}

.recent-donors-head {
  @include alignCenter;
  gap: 15px;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.recent-donors-body {
  width: 100%;
  max-height: 400px;
  height: 100%;
  overflow: auto;
}

.recent-donor-card {
  padding: 16px 0;
  border-bottom: 1px solid $borderGray;

  span {
    color: $darkGray;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }
}

.recent-donor-name-price {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  span {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.recent-donors-footer {
  @include flexCenter;
  padding: 12px 0;

  button {
    @include resetBtn;
    @include alignCenter;
    gap: 8px;
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.charity-detail-modal {
  width: 100%;
  max-width: 760px;

  .charity-detail-modal-head {
    @include spaceBetween;
    padding: 32px;
    gap: 15px;
    border-bottom: 1px solid $borderGray;

    .charity-detail-modal_close {
      @include resetBtn;
      @include flexCenter;
      height: 36px;
      width: 36px;
      min-width: 36px;
      min-height: 36px;
      border-radius: 32px;
      background: $pageGray;
      padding: 9.6px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: #20313F;
        }
      }
    }

    div {
      @include alignCenter;
      gap: 15px;

      p {
        color: #000;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
      }
    }
  }

  .charity-detail-modal-body {
    padding: 32px;

    .charity-detail-modal-desc {
      .charity-desc-title {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }

      .charity-desc-text {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 10px;
      }
    }

    .charity-detail-modal-images {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 100%;
      max-height: 350px;
      overflow-y: auto;
      gap: 16px 16px;
      margin-top: 47px;

      .charity-detail-modal-images_block {
        width: 220px;
        height: 161px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .charity-detail-modal-footer {
    @include flexCenter;
    flex-wrap: wrap;
    padding: 32px;
    gap: 12px;
    border-top: 1px solid $borderGray;


    div {
      @include alignCenter;
      gap: 10px;

      b {
        font-weight: 600;
      }

      a {
        text-decoration: none;
        color: $richNavy;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &.charity-detail-modal_memory {
    background: $chipBack3;

    a, p {
      color: $borderGray !important;
    }

    .charity-detail-modal-head {
      border-bottom: 1px solid $darkGray;

      .charity-detail-modal_close {
        background: $beckBlack2;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: $borderGray;
          }
        }
      }
    }

    .charity-detail-modal-footer {
      border-top: 1px solid $darkGray;
    }

    .charity-detail-modal-action {
      svg {
        circle {
          fill: $white;
        }

        path {
          fill: $chipBack3;
        }
      }
    }

  }
}

//ContributionCard

.contribution-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $pageGray;

  .contribution-card-name {
    color: $richNavy;
    text-overflow: ellipsis;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .contribution-card-date-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    .contribution-card-date, .contribution-card-amount {
      overflow: hidden;
      color: $darkGray;
      text-overflow: ellipsis;
      font-family: Outfit, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .contribution-card-amount {
      color: $richNavy;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .contribution-card-button {
    @include resetBtn;
    width: fit-content;
    color: $buttonBeck;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
  }

  .contribution-card-messages {
    color: $richNavy;
    text-overflow: ellipsis;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

//ContributionCard Accordion detail

.contribution-accordion-details {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .contribution-accordion-details-date {
    display: flex;
    align-items: center;
    gap: 5px;
  }


  .contribution-accordion-details-name, .contribution-accordion-details-description, p {
    color: $darkGray;
    text-overflow: ellipsis;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .contribution-accordion-details-description, p {
    color: $richNavy;
  }
}

