@import "../../../assets/styles/global";

.favor-items-page {
  padding: 80px 0 0;

  .favor-items-box {
    .favor-items-page-back-btn-box {
      margin: 16px 0 8px;
      padding: 12px 0;
    }

    .favor-items-choose-item {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }

    .favor-items-choose-budget {
      color: $darkGray;
      font-family: Outfit, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .favor-items-page-search_input-filter {
      display: flex;
      gap: 10px;
      margin-bottom: 16px;

      .see-items-search_input {
        height: 48px;
      }

      .favor-items-sort-by-button {
        background: $white;

        &.sort-by-button-active {
          background: $backGreen;
        }

        p {
          display: none;
        }
      }
    }

    .favor-items-page_items-box {
      height: 67vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: start;
      gap: 12px;
      overflow-y: auto;

      .favor-items-see-items_single {
        height: fit-content;
      }

      &::-webkit-scrollbar {
        display: block !important;
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #555768;
        border-radius: 10px;
      }
    }
  }

  .favor-items-page-footer {
    width: 100%;
    display: flex;
    gap: 8px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 8px 16px;
    background: white;

    button {
      width: 100%;
    }
  }
}