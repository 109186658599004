@import "../../../assets/styles/global";

.favors-page {
  @include pageWrapper;

  .favors-page-search_input {
    width: 100%;
    margin: 20px 0 24px;
  }

  .favors-table-wrapper {
    .table-wrapper-desktop {
      .table-container {
        height: 58vh;
      }
    }

    .full-table-accordion-wrapper {
      .accordion-table-body {
        height: 56vh;
      }
    }


    .table-wrapper-mobile {
      .table-wrapper-mobile-wrapper {
        height: 64vh;
      }
    }
  }
}

.favorsAccordionDetails-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .favorsAccordionDetails-created-date {
    width: 80%;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 8px;
    background: $pageGray;

    span {
      color: $darkGray;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    p {
      color: $richNavy;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.create-favor-modal-wrapper {
  width: 514px;
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet) {
    width: 100%;
  }
}
