@import "../../assets/styles/global";

.upload-section-box {
  margin-top: 16px;
  gap: 16px 16px;
  width: 100%;

  .file-uploader {
    @include flexCenter;
    border-radius: 8px;
    border: 2px dashed $borderGray;
    padding: 24px;
    margin-bottom: 16px;

    &.file-uploader-error {
      border: 2px dashed $red2;
    }

    label {
      @include flexCenter;
      width: 100%;
      cursor: pointer;
    }

    .upload-info-box {
      @include flexCenter;
      flex-direction: column;
      width: 100%;
    }
  }

  .drag-drop-text {
    color: $richNavy;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    margin: 12px 0 6px;

    span {
      color: $blue2;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-decoration-line: underline;
      margin: 0 4px;
    }
  }

  .information-box {
    text-align: center;
    color: #000624;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    b {
      font-weight: 600;
    }
  }

  .uploaded-images-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
    @media (min-width: $desktop) {
      justify-content: start;
    }

    .uploaded-image {
      @include flexCenter;
      position: relative;
      z-index: 1;
      border-radius: 8px;
      padding: 4px;

      img {
        width: 150px;
        height: 109px;
        object-fit: cover;
        border-radius: 8px;

        @media (min-width: $tablet) {
          width: 200px;
          height: 161px;
        }
      }
    }

    .remove-image {
      @include flexCenter;
      width: 24px;
      height: 24px;
      background: white;
      box-shadow: 0 0 14px 0 rgba(208, 214, 220, 0.60);
      position: absolute;
      z-index: 2;
      right: 8px;
      top: 8px;
      border-radius: 40px;
      border: none;
    }
  }

  .single_uploading_img_wrapper {
    width: 56px;
    min-width: 56px;
    height: 56px;
    border-radius: 8px;
    overflow: hidden;

    .single_uploading_img-box {
      width: 100%;
      height: 100%;
      position: relative;

      .uploading_img-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .single_uploading_delete-icon {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(11, 11, 11, 0.15);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        transition: all .2s;

        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 24px;
          height: 24px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5%) hue-rotate(53deg) brightness(101%) contrast(104%);
          cursor: pointer;
          margin: auto;
        }
      }

      &:hover {
        .single_uploading_delete-icon {
          opacity: 1;
          visibility: visible;
          z-index: 2;
          transition: all .2s;
        }
      }
    }
  }
}