@import "../../assets/styles/global";

.input-main-wrapper {
  text-align: start;
  width: 100%;


  .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #3D4751 !important;
  }

  .Mui-disabled {
    -webkit-text-fill-color: #3D4751 !important;
  }

  .MuiInputAdornment-root {
    cursor: pointer;
    width: fit-content;
    height: fit-content;

    &.error-svg {
      svg {
        path {
          stroke: $red;
        }
      }
    }

    &.error-svg-password {
      svg {
        fill: $red;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      display: flex;
      align-items: center;
    }

    input {
      padding: 12px 16px;
    }


    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $red;
      }
    }
  }

  &.input-custom-wrapper {
    .MuiInputBase-root {
      border-radius: 8px;
      outline: none;

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          //border: 2px solid;
        }
      }
    }

    input {
      padding: 12px 16px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $darkGray;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $richNavy;
      }
    }


    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $red;
      }
    }
  }

  .MuiInputAdornment-root ~ input {
    padding-left: 0;
  }

  .MuiSvgIcon-root {
    fill: #000;
  }
}


.accordion-wrapper {

  .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    min-height: 47px;
    border: none;
  }

  .MuiOutlinedInput-root {
    min-height: 47px;
    border-radius: 8px;
    border: none !important;
    outline: none;
    padding: 0 16px !important;
  }


  //.MuiOutlinedInput-notchedOutline {
  //  border-color: #9D9D9D;
  //  outline: none;
  //}
  //
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid $richNavy !important;
    }
  }


  .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }


}

.main-select-option {
  color: $textColor2;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 16px;
  cursor: pointer;

  &.main-select-option-selected, &:hover {
    color: $textColor2;
    background: $pageGray;
  }


}

.input-main-label-header {
  color: $richNavy;
  font-weight: 500;
  margin-bottom: 8px;

  .input-main-label-header-required {
    color: $richNavy;
  }

  &.errorLabel {
    color: $richNavy;

    .input-main-label-header-required {
      color: $richNavy;
    }

  }

}

.input-select-wrapper {
  .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 47px;
    border: none;
  }

  .MuiOutlinedInput-root {
    height: 47px;
    border: none !important;
    outline: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $darkGray;
    outline: none;
  }
}

.error-message {
  color: $red;
  font-size: 12px;
  font-weight: 400;
  min-height: 18px;
  margin-left: 15px;
  margin-top: 2px
}

.checkbox-box {
  width: fit-content;

  .MuiFormControlLabel-root {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;

    .MuiButtonBase-root {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
}


.custom-select-btn-box {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #D0D2D7;
  background: #FFF;
  padding: 6px 8px;
  height: 48px;
  @media (min-width: $tablet) {
    padding: 6px 16px;
  }

  button {
    @include resetBtn;
    @include alignCenter;
    gap: 8px;
    padding: 0;
  }

  p {
    white-space: nowrap;
  }
}

.custom-select-btn-active-box {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #000;
  padding: 6px 8px;
  height: 48px;
  @media (min-width: $tablet) {
    padding: 6px 16px;
  }

  button {
    @include resetBtn;
    @include alignCenter;
    gap: 8px;
    padding: 0;
    color: #FFF;

    svg {
      filter: brightness(0) invert(1);
    }
  }

  p {
    white-space: nowrap;
  }
}

.note-custom-select-paper-box {
  width: 100%;
  @media (min-width: $tablet) {
    width: 500px;
  }
  @media (max-width: $tablet) {
    transform: none !important;
  }

  .MuiBox-root {
    margin-top: 4px;
    width: 100%;
    border: 1px solid #F4F4F4;
    padding: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);

    @media (min-width: $tablet) {
      width: 300px;
    }
  }
}

.notifications-box {
  height: 100vh;
  width: 100%;
  background-color: #FFFFFF;

  @media (min-width: $tablet) {
    height: 600px;
    width: 500px;
    box-shadow: 0px 2px 6px #0000001A;
    border-radius: 16px;
    margin-top: 34px;
  }
}

.custom-select-paper-box {
  .MuiBox-root {
    margin-top: 16px;
    margin-left: -16px;
    width: 300px;
    border: 1px solid #F4F4F4;
    padding: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  }
}

.custom-select-paper-box-custom {
  z-index: 9999;

  .MuiBox-root {
    margin-top: 4px;
  }
}

.address-suggestion-custom-input-wrapper {
  position: absolute;
  width: 100%;
  border-radius: 4px;
  background: white;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;

  .suggestion-item {
    color: black;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 15px;
    cursor: pointer;
  }
}

.custom-switch-box {
  .MuiSwitch-root {
    padding: 0;
    height: 20px;
    width: 41px;
  }

  .MuiSwitch-switchBase {
    padding: 0;
  }

  .MuiButtonBase-root-MuiSwitch-switchBase {
    padding: 0 !important;
    left: 2px;
  }

  .MuiSwitch-thumb {
    height: 16px;
    width: 16px;
    margin-top: 2px;
    margin-left: 2px;
  }

  .MuiSwitch-track {
    border-radius: 12px;
  }

  .MuiSwitch-switchBase.Mui-checked {
    color: white;
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: #000000;
  }
}

.date-range-selector-mobile {
  @include alignCenter;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #D0D2D7;
  background: #FFF;
  height: 48px;
  box-shadow: 0 0 14.87px 0 rgba(208, 214, 220, 0.60);

  svg {
    filter: invert(0.5);
  }

  @media (min-width: $tablet) {
    display: none;
  }
}

.date-range-selector {
  display: none;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #D0D2D7;
  background: #FFF;
  height: 48px;
  box-shadow: 0 0 14.87px 0 rgba(208, 214, 220, 0.60);

  @media (min-width: $tablet) {
    display: flex;
    align-items: center;
  }

  p {
    color: rgba(34, 34, 34, 0.80);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  svg {
    filter: invert(0.5);
  }
}

.search-box {
  @include alignCenter;
  padding: 0 16px;
  gap: 16px;
  display: flex;
  width: 100%;
  height: 40px;
  background: #FFF;
  border-radius: 8px;

  @media (min-width: $tablet) {
    width: 680px;
  }
  @media (min-width: $desktop) {
    width: 312px;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
  }

  .search-box-button {
    @include resetBtn;
  }

  .search-clear-button {
    @include resetBtn;

    svg {
      filter: brightness(0) saturate(100%) invert(18%) sepia(17%) saturate(920%) hue-rotate(159deg) brightness(97%) contrast(97%);
    }
  }
}

//TextAreaMain

.main-textarea-wrapper {

  .textarea-main-label-header {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    &.errorLabel {
      color: $red;

      .textarea-main-label-header-required {
        color: $red;
      }
    }

    .textarea-main-label-header-required {
      color: $richNavy;
    }
  }

  .textarea {
    width: 100%;
    height: 120px;
    resize: none;
    font-family: Outfit, sans-serif;
    padding: 12px 16px;
    border: 1px solid #9D9D9D;
    color: $richNavy;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 8px;

    &.textarea-error {
      border: 1px solid $red;

      &:hover {
        border: 1px solid $red;
        outline: 1px solid $red;
      }

      &:focus {
        border: 1px solid $red;
        outline: 1px solid $red;
      }
    }

    &:hover {
      border: 1px solid $richNavy;
      outline: none;
    }

    &:focus {
      border: 1px solid $richNavy;
      outline: none;
      //outline: 1px solid $richNavy;
    }

    &::placeholder {
      color: #C4C4C4;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.custom-numeric-format-box {
  position: relative;

  .numeric-autocomplete-label {
    position: absolute;
    padding: 16px 14px;
    font-size: 16px;
    color: #51566D;
    z-index: 1;
    transition: all .2s;

    &.numeric-autocomplete-label-active {
      transition: all .2s;
      font-size: 12px;
      color: $richNavy;
      position: absolute;
      background: white;
      margin-top: -4px;
      padding: 0 6px;
      margin-left: 8px;
      z-index: 3;
    }
  }


  .numeric-format-input {
    width: 100%;
    height: 48px;
    padding: 13px 16px;
    border-radius: 8px;
    border: 1px solid $richNavy;
    background: #FFF;
    color: $darkGray;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    position: relative;
    z-index: 2;


    &:focus {
      outline: none;
      border: 2px solid $richNavy;
    }
  }

  .numeric-format-input-error {
    width: 100%;
    height: 48px;
    padding: 13px 16px;
    border-radius: 4px;
    border: 1px solid $red;
    background: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;

    &:focus {
      outline: none;
      border: 2px solid $richNavy;
    }

    &::placeholder {
      color: $red;
    }
  }


  .numeric-autocomplete-label-error {
    color: $red;
  }
}

//DateInput

.date-input-box {
  position: relative;
  width: 100%;

  .helper-text-wrapper {
    @include flexStart;
    gap: 4px;
    color: $richNavy;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 4px 4px 0;

    svg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;

      path {
        fill: $buttonBeck;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .date-input-title {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .react-datepicker__input-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }

    button {
      p {
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
    }

  }

  .react-datepicker__calendar-icon {
    margin-right: 12px;
    z-index: 1;

    path {
      fill: $richNavy;
    }
  }

  .date-picker-box {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid $richNavy;
    background: transparent;
    padding: 0 16px;
    font-size: 16px;
    color: $richNavy;
    position: relative;
    z-index: 2;
    outline: none;
    text-align: start;

    &:focus {
      outline: 1px solid $richNavy;
    }
  }

  .date-picker-search-box {
    width: 200px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid $richNavy;
    background: transparent;
    padding: 0 16px;
    font-size: 16px;
    color: $richNavy;
    position: relative;
    z-index: 2;
    outline: none;
    text-align: start;
  }

  .date-picker-error {
    border: 1px solid $red;

    &:focus {
      outline: 1px solid $red;
    }

  }

  .date-picker-disabled {
    border: 1px solid $darkGray;

    &:focus {
      outline: none;
    }
  }

  .react-datepicker__close-icon {
    margin-right: 40px;
  }

  .react-datepicker__close-icon::after {
    z-index: 4;
    position: relative;
    background: transparent;
    color: #51566D;
    font-size: 24px;
  }

  .react-datepicker-popper {
    z-index: 10;
  }
}

//DATE CALENDAR

.date-calendar-wrapper {
  width: 100%;

  .date-input-title {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .clear-bnt {
    background: transparent;
    border: none;
    outline: none;
    margin-top: -32px;
    width: 85px;
    color: #0C66E4;
    font-size: 16px;
  }

  .date-calendar-main-button {
    width: 100%;
    height: 48px;
    @include resetBtn;
    @include alignCenter;
    border-radius: 8px;
    border: 1px solid $richNavy;
    outline: 0 solid $richNavy;
    padding: 2px;

    &:focus {
      outline-width: 1px;
      border-width: 1px;
    }

    &.date-calendar-main-button-focused {
      outline-width: 1px;
      border-width: 1px;
    }

    .remove-calendar-day {
      @include resetBtn;
      width: 21px;
      height: 21px;
      margin-top: 5px;
    }

    .calendar-icon-clear-button {
      @include alignCenter;
      padding-right: 16px;
    }


    .date-range-inputs {
      width: 100%;
      height: 100%;
    }

    .rs-input {
      width: 100%;
      height: 100%;
      color: $richNavy;
      border: none;
      outline: none;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;

      &::placeholder {
        color: #C4C4C4;
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

.modal-custom-date-selector-box {
  display: flex;
  flex-direction: column;
  z-index: 999999 !important;

  .rdrCalendarWrapper {
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
    border-radius: 8px;
    background: white;
    padding-bottom: 20px;
  }

  .rdrCalendarWrapper {
    margin-top: 0;
  }


  .rdrDayNumber span {
    color: $richNavy;

    &:after {
      background: $buttonBeck;
    }
  }


  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-color: $buttonBeck;

  }

  .rdrSelected {
    background-color: $buttonBeck;
  }


  .rdrDefinedRangesWrapper {
    display: none;
  }

  .clear-bnt {
    background: transparent;
    border: none;
    outline: none;
    margin-top: -32px;
    width: 85px;
    color: $buttonBeck;
    font-size: 16px;
  }
}