@import "../../assets/styles/global";

.favor-small-card-wrapper {
  width: 120px;

  &.favor-small-card-active {
    .favor-small-card-box {
      border: 1px solid $buttonBeck;
    }
  }

  &:hover {
    .favor-small-card-box {
      .favor-small-card-image {
        .favor-small-card_action-checkbox-button {
          .favor-small-card-close-svg {
            display: block;
          }
        }
      }
    }
  }


  .favor-small-card-box {
    width: 100%;
    @include alignCenter;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid $borderGray;
    background: $white;

    .favor-small-card-image {
      width: 100%;
      position: relative;
      height: 111px;

      .favor-small-card_action-checkbox-button {
        @include resetBtn;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 3px;
        right: 5px;


        .favor-small-card-checkbox-svg {
          width: 100%;
          height: 100%;
        }

        .favor-small-card-close-svg {
          display: none;
          width: 100%;
          height: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .favor-small-card-price_title {
      width: 100%;
      padding: 4px 6px;

      .favor-small-card-price {
        width: 100%;

        p {
          width: 100%;
          color: $richNavy;
          font-family: Outfit, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .favor-small-card-name {
        color: $darkGray;
        font-family: Outfit, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 2px;
      }
    }
  }
}

//ItemsAndTooltip

.items-and-tooltip-wrapper {
  .items-and-tooltip-box {
    display: flex;

    .items-and-tooltip-image {
      width: 34px;
      min-width: 34px;
      height: 34px;
      border-radius: 8px;
      border: 1px solid $white;
      background: $white;
      overflow: hidden;

      &.items-and-tooltip-image-margin {
        margin-left: -16px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .favor-items-images-and-tooltip-wrapper {
      @include flexCenter;
      width: 34px;
      min-width: 34px;
      height: 34px;
      border-radius: 8px;
      border: 1px solid $white;
      background: $white;
      overflow: hidden;
      margin-left: -16px;
      background: #000;
      cursor: pointer;

      &.favor-items-images-and-tooltip-text {
        color: $white !important;
        font-family: Outfit, sans-serif !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        letter-spacing: 0.16px !important;
      }
    }
  }
}

.favor-items-images-and-tooltip {
  border-radius: 4px;
  padding: 8px 16px !important;
  background: $richNavy !important;
  margin-top: 4px !important;

  color: $white;
  text-align: center;

  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.favors-images-slide-modal {
  border-radius: 0 !important;
  background: transparent !important;
  overflow: visible !important;
}

.favors-images-two-slides-wrapper {
  width: 559px;

  @media (max-width: $desktop) {
    width: 588px;
  }

  @media (max-width: $tablet) {
    max-width: 343px;
    width: 100%;
  }

  .favors-images-slide-main-slider-wrapper {
    position: relative;
    width: 100%;
    height: 659px;

    @media (max-width: $desktop) {
      height: 730px;
    }

    @media (max-width: $tablet) {
      height: 520px;
    }


    .favors-images-slide-main-slider-box {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
    }

    .favors-images-slide-main-slider-next, .favors-images-slide-main-slider-prev {
      width: 60px;
      height: 60px;
      @include resetBtn;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (max-width: $desktop) {
        display: none;
      }

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $buttonBeck;
        }
      }

      &:disabled {
        svg {
          width: 100%;
          height: 100%;

          path {
            fill: $white;
          }
        }
      }
    }

    .favors-images-slide-main-slider-next {
      transform: translateY(-50%) rotate(180deg);
      left: -60px;
    }

    .favors-images-slide-main-slider-prev {
      right: -60px;
      transform: translateY(-50%);
    }
  }

  .favors-images-slide-thumbs-slider-box {
    width: 100%;
    margin-top: 19px;

    @media (max-width: $tablet) {
      margin-top: 16px;
    }

    .swiper-wrapper {


      .swiper-slide {
        height: 117px;
        padding: 0 7.5px;

        @media (max-width: $desktop) {
          height: 75px;
          padding: 0 4px;
        }

        .small-swipe-items {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;
        }

        &.swiper-slide-thumb-active {
          .small-swipe-items {
            border: 2px solid $buttonBeck;
          }
        }
      }
    }
  }

}


