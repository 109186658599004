@import "../../../assets/styles/global";

.start-event-page {
  font-family: Outfit, sans-serif;
  //background: no-repeat url("../../../assets/images/img/darkLightImg.png");
  background-size: 100% 100%;

  @media (max-width: $desktop) {
    //background: no-repeat url("../../../assets/images/img/darkLightTabletImg.png");
    background-size: 100% 100%;
  }

  .start-event-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 168px 0 128px;

    @media (max-width: $desktop) {
      padding: 291px 0 285px;
    }


    @media (max-width: $tablet) {
      padding: 123px 0 165px;
    }

    .start-event-title_desc {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media (max-width: $tablet) {
        max-width: 548px;
      }


      .start-event-title {
        color: $richNavy;
        font-family: Outfit,sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
      }

      .start-event-desc {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }




    .dark-white-block {
      display: flex;

      .dark-img-wrapper {
        position: relative;
        display: flex;
        align-items: flex-start;

        @media (max-width: $tablet) {
          flex-direction: column;
        }


        .dark-img-block{
          & :hover{
            box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
          }
        }

        .dark-img-button_arrow {
          width: fit-content;
          height: fit-content;
          position: absolute;
          top: 0;
          bottom: 120px;
          left: 0;
          right: 80px;
          margin: auto;
          z-index: 7;

          @media (max-width: $tablet) {
            bottom: 70px;
            right: 40px;
          }


          .dark-img-button {
            @include resetBtn;
            //display: flex;
            //align-items: center;
            //gap: 4px;
            //border-radius: 32px;
            //background: $white;
            //border: none;
            //padding: 16px 24px;
            //transition: all .3s;
            //
            //@media (max-width: $desktop) {
            //  padding: 15px 16px;
            //}
            //
            //@media (max-width: $tablet) {
            //  padding: 8px 16px;
            //}
            //
            //&:hover {
            //  background: $blue2;
            //  transition: all .3s;
            //
            //  p {
            //    color: $white;
            //  }
            //
            //  svg {
            //    path {
            //      fill: $white;
            //    }
            //  }
            //}

            p {
              color: $white;
              font-family: Outfit,sans-serif;
              font-size: 32px;
              font-weight: 500;
              line-height: 30px;

              @media (max-width: $tablet) {
                font-size: 20px;
              }
            }
          }

          .dark-img-arrow {
            margin: 10px 0 0 85px;

            @media (max-width: $desktop) {
              width: 112px;
              height: 26px;
            }

            @media (max-width: $tablet) {
              width: 95px;
              height: 22px;
              transform: rotate(41.664deg);
              margin: 35px 0 0 62px;
            }

          }
        }

        .dark-img-block {
          width: 376px;
          height: 482px;
          cursor: pointer;
          overflow: visible;

          //@media (max-width: $desktop) {
          //  width: 259px;
          //  height: 323px;
          //}


          @media (max-width: $tablet) {
            width: 232px;
            height: 297px;
          }
        }
      }

      .white-img-wrapper {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        margin: 40px 0 0 -238px;


        //@media (max-width: $desktop) {
        //  margin: 20px 0 0 -180px;
        //}


        @media (max-width: $tablet) {
          margin: 23px 0 0 -153px;
        }

        .white-img-button_arrow {
          width: fit-content;
          height: fit-content;
          position: absolute;
          top: 70px;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;

          @media (max-width: $tablet) {
            top: 48px;
          }


          //.white-img-arrow {
          //  margin: 0 0 10px -85px;
          //
          //  @media (max-width: $desktop) {
          //    width: 156px;
          //    height: 36px;
          //    margin: 0 0 10px -70px;
          //  }
          //
          //  @media (max-width: $tablet) {
          //    width: 95px;
          //    height: 22px;
          //    transform: rotate(41.664deg);
          //    margin: 0 0 33px 12px;
          //  }
          //}

          .white-img-button {
            @include resetBtn;
            //display: flex;
            //align-items: center;
            //gap: 4px;
            //border-radius: 32px;
            //padding: 16px 24px;

            @media (max-width: $desktop) {
              //padding: 15px 16px;
            }


            @media (max-width: $tablet) {
              //padding: 8px 16px;
            }

            //&:hover {
            //  background: $buttonBeck;
            //  transition: all .3s;
            //
            //  p {
            //    color: $white;
            //  }
            //
            //  svg {
            //    path {
            //      fill: $white;
            //    }
            //  }
            //}

            p {
              color: #000624;
              font-family: Montserrat,sans-serif;
              font-size: 32px;
              font-weight: 500;
              line-height: 34px;

              @media (max-width: $tablet) {
                font-size: 20px;
              }
            }
          }
        }

        .white-img-block {
          width: 370px;
          height: 473px;
          cursor: pointer;

          //@media (max-width: $desktop) {
          //  width: 259px;
          //  height: 323px;
          //}


          @media (max-width: $tablet) {
            width: 228px;
            height: 292px;
          }
        }
      }
    }
  }
}


.yin-part, .yang-part {
  filter: url(#defaultShadow);
  transition: filter 0.2s ease-in-out;
}
.yin-part:hover, .yang-part:hover {
  filter: url(#hoverShadow);
}