@import "../../assets/styles/global";

.breadcrumb-wrapper {
  display: flex;
  align-items: center;

  .breadcrumb-link-icon{
    @include alignCenter;
  }

  a {
    color: $darkGray;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 10px;
    text-decoration: none;
  }

  p {
    color: $richNavy;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 10px;
  }
}