@import "../../assets/styles/global";


.modal-header-component {
  @include spaceBetween;
  padding: 16px;
  border-bottom: 1px solid $borderGray;
  @media (min-width: $tablet) {
    padding: 16px 32px;
  }

  .modal-title {
    color: $richNavy;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
}

.modal-form-box {
  padding: 16px;
  @media (min-width: $tablet) {
    padding: 16px 32px;
  }
}

.modal-actions-box {
  @include spaceBetween;
  gap: 18px;
  padding: 16px;
  border-top: 1px solid $borderGray;
  @media (min-width: $tablet) {
    padding: 16px 32px;
  }

  button {
    width: 100%;
  }
}

.default-actions-box {
  @include spaceBetween;
  gap: 18px;

  button {
    width: 100%;
  }
}

.delete-modal {
  width: 100%;
  max-width: 310px;
  padding: 24px;
  @include column;

  @media (min-width: $tablet) {
    max-width: 407px;
  }

  .delete-modal-title {
    color: $richNavy;
    font-family: Outfit,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 4px 0;
  }

  .delete-modal-desc {
    color: $richNavy;
    font-family: Outfit,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .delete-modal-no-btn {
    width: 100%;
    @include resetBtn;
    color: $textColor1;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
    border-radius: 4px;
    background: $pageGray;

    @media (min-width: $tablet) {
      width: fit-content;
    }
  }

  .main-button {
    width: 100%;
    padding: 0;
    border-radius: 4px;
    background: $red2;

    @media (min-width: $tablet) {
      width: 75px;
    }
  }
}
