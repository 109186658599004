@import "../../../assets/styles/global";

.fundraiser-details-wrapper {
  width: 100%;


  .fundraiser-details-page {
    @include pageWrapper;

    .fundraiser-details-tab {
      @include spaceBetween;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 0 24px 0;
      @media (min-width: $desktop) {
        padding: 32px 0 24px 0;
        flex-direction: row;
        align-items: center;
      }
    }

    .event-details-back-btn_memory {
      margin-top: 39px;

      @media (min-width: $tablet) {
        margin-top: 45px;
      }

      @media (min-width: $desktop) {
        margin-top: 36px;
      }
    }

    .search-box {
      margin-top: 24px;
      @media (min-width: $desktop) {
        margin-top: 0;
      }
    }

    .fundraiser-detail-screen-wrapper {
      @include column;
      gap: 16px;

    }

    .detail-page-wrapper-memory {
      .detail-page-head {
        background: $chipBack3;
        border-bottom: 1px solid $darkGray;

        .detail-page-title {
          color: $borderGray;
        }

        .fundraiser-detail-actions {

          svg {
            path {
              fill: $white;
            }
          }

          p {
            color: $white;
          }
        }

        .share-or-publish-box {
          .finish-button-memory {
            background: $white;

            p {
              color: $chipBack3;
            }

            svg {
              path {
                fill: $chipBack3;
              }
            }
          }
        }
      }

      .detail-page-body {
        background: $chipBack3;

        p {
          color: $borderGray;
        }

        .event-information-single, .assigned-charities-wrapper, .fundraiser-detail-description {
          border: 1px solid $darkGray;

        }

        .assigned-charities-wrapper {
          .assigned-charity-single {
            background: $white;
            color: $chipBack3;
          }
        }

        .fundraiser-detail-description {
          span {
            color: $borderGray;
          }
        }

        .event-info_upload-image-memory {
          .file-uploader {
            border: 2px dashed $darkGray;

            span {
              color: $white;
            }
          }
        }
      }

      &.can-deleted-wrapper {
        .delete-btn-box {
          svg {
            path {
              stroke: $red2;
            }
          }

          p {
            color: $red2;
          }
        }

        .info-svg {
          path {
            fill: $white;
          }
        }
      }

      .memory-input {
        .error-message {
          color: $red2;
        }

        .input-main-label-header {
          color: $white;

          .input-main-label-header-required {
            color: $white;
          }

          &.errorLabel {
            color: $red2;

            .input-main-label-header-required {
              color: $red2;
            }
          }
        }


        .date-input-title {
          color: $borderGray !important;
        }

        .react-datepicker__input-container {

          svg {
            path {
              fill: $borderGray !important;
            }
          }

          button {
            p {
              color: $borderGray !important;
            }

            &::after {
              color: $borderGray !important;
            }
          }

          .date-picker-box {
            border: 1px solid $borderGray !important;
          }
        }

        &.date-input-box-error {
          .date-input-title {
            color: $red2 !important;
          }

          .date-picker-box {
            border: 1px solid $red2 !important;
          }
        }


        .MuiInputBase-root {
          color: $white;

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border: 2px solid $white;
            }

            &:hover {
              .MuiOutlinedInput-notchedOutline {
                border: 2px solid $white;
              }
            }
          }

          &.Mui-error {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid $red2;
            }

            &:hover {
              .MuiOutlinedInput-notchedOutline {
                border: 1px solid $red2;
              }
            }

            &.Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border: 2px solid $red2;
              }

              &:hover {
                .MuiOutlinedInput-notchedOutline {
                  border: 2px solid $red2;
                }
              }
            }
          }


          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid $borderGray;
            }
          }

          input {
            color: $white;
          }


          input[type="date"] {
            color: white;
            color-scheme: dark;
          }


          input[type="date"]::-webkit-datetime-edit-text {
            color: $white;
          }

          input[type="date"]::-webkit-datetime-edit-month-field,
          input[type="date"]::-webkit-datetime-edit-day-field,
          input[type="date"]::-webkit-datetime-edit-year-field {
            color: $white;
          }


          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $borderGray;
          }

          .MuiInputAdornment-positionStart {
            svg {
              path {
                stroke: $white;
              }
            }
          }

          .MuiInputAdornment-positionEnd {
            svg {
              fill: $white;
            }
          }
        }
      }

      .memory-textarea {
        .textarea-main-label-header {
          color: $white;

          .textarea-main-label-header-required {
            color: $white;
          }

          &.errorLabel {
            color: $red2;

            .textarea-main-label-header-required {
              color: $red2;
            }
          }
        }

        .textarea {
          background: transparent;
          color: $white;
          border: 1px solid $borderGray;

          &:focus {
            border: 2px solid $borderGray;
          }

          &.textarea-error {
            border: 1px solid $red2;

            &:hover {
              border: 1px solid $red2;
              outline: 1px solid $red2;
            }

            &:focus {
              border: 1px solid $red2;
              outline: 1px solid $red2;
            }
          }
        }

        .error-message {
          color: $red2;
        }
      }

      .memory-autocomplete {

        //.MuiOutlinedInput-notchedOutline {
        //  border-color: #9D9D9D;
        //  outline: none;
        //}
        //
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $borderGray !important;
          }
        }

        &:disabled {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $borderGray !important;
          }
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 2px solid $borderGray !important;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $borderGray !important;
        }

        .MuiChip-root {
          background: $borderGray;
          color: $chipBack3;
        }

        .MuiAutocomplete-endAdornment {
          .MuiSvgIcon-root {
            fill: $borderGray;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          & :hover {
            border: 1px solid $borderGray !important;
          }
        }

        .MuiAutocomplete-input {
          padding: 0;
        }

        .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
          padding: 0;
        }
      }

      .detail-page-footer {
        background: $chipBack3;
        border-top: 1px solid $darkGray;

        .edit-fundraiser-save-cancel {
          .default-actions-box {
            .close-btn {
              color: $borderGray;
            }

            .main-button {
              background: $white;

              p {
                color: $chipBack3;
              }

              &:hover {
                background: #E0E1E2;
              }
            }
          }
        }
      }
    }


    .fundraiser-detail-actions {
      @include alignCenter;
      gap: 16px;
      background: transparent;

      .main-button {
        height: 36px;
      }
    }

    .share-or-publish-box {
      display: none;

      @media (min-width: $tablet) {
        @include alignCenter;
        gap: 16px;
      }

      .events_share-btn {
        width: 42px;
        height: 36px;

        svg {
          min-width: 24px;
          min-height: 24px;
        }
      }
    }

    .share-btn-desktop {
      @include resetBtn;
    }

    .share-or-publish-mobile-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 16px;
      margin-top: 16px;
      width: 100%;
      border-top: 1px solid $borderGray;
      @media (min-width: $tablet) {
        display: none;
      }

      .main-button {
        width: 100%;
      }

      .finish-button-memory {
        width: 100%;
        background: $white;

        p {
          color: $chipBack3;
        }

        svg {
          path {
            fill: $chipBack3;
          }
        }
      }
    }

    .event-information-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: $desktop) {
        flex-direction: row;
        gap: 9px;
      }


      .event-information-single {
        width: 100%;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid $borderGray;

        &.person-details {
          width: 100%;

          @media (min-width: $desktop) {
            width: 30%;
          }
        }

        &.event-details {
          width: 100%;

          @media (min-width: $desktop) {
            width: 70%;
          }
        }

        .event-information_title {
          color: $darkGray;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }

    .assigned-charities-wrapper {
      border-radius: 8px;
      border: 1px solid $borderGray;
      padding: 12px;
      margin-top: 16px;

      .assigned-charities-title {
        color: $darkGray;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .assigned-charities-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        @media (min-width: $tablet) {
          gap: 16px;
        }

        .assigned-charity-single {
          width: 100%;
          color: $richNavy;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          background: $backGreen;
          border-radius: 8px;
          padding: 8px 16px;

          @media (min-width: $tablet) {
            width: fit-content;
          }
        }
      }
    }


    .fundraiser-detail-description {
      border-radius: 8px;
      border: 1px solid $borderGray;
      padding: 12px;

      p {
        color: $darkGray;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 8px;
      }

      span {
        color: $richNavy;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        overflow-wrap: break-word;
      }
    }
  }

}


.end-event-modal {
  width: 100%;
  max-width: 310px;
  padding: 24px;
  @media (min-width: $tablet) {
    max-width: 412px;
  }

  @include column;
  gap: 16px;

  .end-event-modal-title {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .end-event-modal-desc {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .end-event-modal-no-btn {
    width: 100%;
    @include resetBtn;
    color: $richNavy;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 24px;
    border-radius: 4px;
    background: $pageGray;

    @media (min-width: $tablet) {
      width: fit-content;
    }
  }

  .main-button {
    width: 100%;
    padding: 0;
    border-radius: 4px;
    background: $red2;

    &:hover {
      background: $red2;
    }

    &:disabled {
      background: $red2;
    }

    @media (min-width: $tablet) {
      width: 75px;
    }
  }
}

.edit-fundraiser-inputs-box {
  @include flexStart;
  flex-direction: column;
  gap: 0;
  @media (min-width: $tablet) {
    flex-direction: row;
    gap: 16px;
  }

  .accordion-wrapper {
    width: 100%;
  }

  .input-main-wrapper {
    width: 100%;
  }
}

.edit-fundraiser-inputs-desktop-box {
  @include flexStart;
  flex-direction: column;
  gap: 0;

  @media (min-width: $desktop) {
    flex-direction: row;
    gap: 16px;
  }

  .accordion-wrapper {
    width: 100%;
  }

  .input-main-wrapper {
    width: 100%;
  }
}

.edit-fundraiser-save-cancel {
  @include flexEnd;

  .default-actions-box {
    width: 410px;
  }
}

.fundraiser-details-card-wrapper {
  border-radius: 8px;
  background: $white;
  border: 1px solid $borderGray;
  overflow: hidden;

  .fundraiser-details-card-header {
    border-bottom: 1px solid #EFF2F6;
    padding: 12px 16px;

    p {
      color: $richNavy;
      font-family: Outfit, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .fundraiser-details-card-content {
    .fundraiser-details-single-card {
      display: flex;
      justify-content: space-between;
      gap: 9px;
      padding: 6px 16px;

      p {
        color: $darkGray;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .fundraiser-details-single-card-desk {
        color: $richNavy;
        text-align: right;
        font-family: Outfit, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        &.service-fee {
          color: $red2;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }

        &.total {
          color: $buttonBeck;
        }
      }
    }
  }
}