@import "../../../assets/styles/global";

.setting-page {
  @include pageWrapper;

  .tab-and-search {
    padding: 24px 0;
  }

  .profile-account-block {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $tablet) {
      gap: 24px;
    }
  }

  .profile-account-wrapper {
    border-radius: 8px;
    background: $white;
  }

  .profile-account-box {
    width: 100%;

    @media (min-width: $tablet) {
      max-width: 534px;
    }
  }

  .upload-images-wrapper {
    display: flex;
    align-items: end;
    gap: 12px;

    label{
      outline: none;
    }
    input {
      outline: none;
    }

    .drag-drop-component {
      width: 100%;
      padding: 16px 0 0 16px;

      @media (min-width: $tablet) {
        padding: 24px 0 0 24px;
      }

      input[type='file'] {
        display: none;
      }

      .profile-avatar-box {
        @include flexCenter;
        width: 97px;
        height: 97px;
        border-radius: 50%;
      }

      img {
        width: 97px;
        height: 97px;
        border-radius: 50%;
        object-fit: cover;
      }

      .upload-btns-box {
        @include alignCenter;
        gap: 10px;
      }
    }

    .upload-images-actions {
      display: flex;
      align-items: center;
      gap: 12px;

      .upload-image-button {
        @include flexCenter;
        border: none;
        height: 32px;
        padding: 7px;
        border-radius: 4px;
        background: $backgroundGray;
        cursor: pointer;
      }
    }
  }

  .account-header-title {
    color: $richNavy;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .profile-account-firstName_username {

    @media (min-width: $tablet) {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }


  .profile-account-header-box {
    padding: 16px;
    border-bottom: 1px solid $borderGray;
    @media (min-width: $tablet) {
      padding: 24px 32px;
    }
  }

  .profile-account-header-title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .profile-account-body-box {
    padding: 16px;

    @media (min-width: $tablet) {
      padding: 24px;
    }
  }

  .profile-account-actions-wrapper {
    padding: 16px;
    border-top: 1px solid $borderGray;

    @media (min-width: $tablet) {
      padding: 16px 24px;
    }

    .profile-account-actions-box {
      width: 100%;
      max-width: 100%;

      @media (min-width: $sm) {
        max-width: 276px;
        margin-left: auto;
      }

      @media (min-width: $tablet) {
        max-width: 534px;
        margin-left: 0;
      }


      .default-actions-box {

        @media (min-width: $desktop) {
          max-width: 276px;
          margin-left: auto;
        }
      }
    }
  }

  .delete-account-wrapper {
    margin-top: 24px;
    padding: 24px;
    background: white;
    border-radius: 8px;
  }
}


.settings-connecting-bank-wrapper {
  width: 80vw;

  .settings-connecting-bank-header {
    padding: 16px 32px;

    @media (max-width: $tablet) {
      padding: 16px;
    }

    p {
      color: $richNavy;
      font-family: "Open Sans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;

      @media (max-width: $tablet) {
        font-size: 20px;
      }
    }
  }

  .settings-connecting-bank-body {
    padding: 16px 32px;

    @media (max-width: $tablet) {
      padding: 16px;
    }
  }

  .settings-connecting-bank-buttons {
    display: flex;
    gap: 18px;
    padding: 16px 32px;
    border-top: 1px solid $borderGray;

    @media (max-width: $tablet) {
      padding: 16px;
    }


    .close-btn, .main-button {
      width: 100%;
    }

    .close-btn {
      border-radius: 8px;
      border: 1px solid $borderGray;
    }
  }

  &.settings-personal-detail {
    max-width: 654px;
  }
}